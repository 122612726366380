import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './scroll/index.js'
import("./assets/css/common_style.css")
import("./assets/css/ant_style.css")
// import("./assets/js/rem.js")
import ("./I18next")
ReactDOM.render(
  <BrowserRouter>
	<ScrollToTop>
		<App />
	</ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
