import React, { useState,useEffect } from 'react'
import { CheckList,Dropdown,Selector } from 'antd-mobile';
import { withRouter } from "react-router";
import Houselist from '../../components/Houselist_transfer'
import TabBar from '../../components/Tabbar'
import {post,get} from '../../service'
import LngSet from '../../components/Lng_set'
import { useTranslation } from "react-i18next";
import styled from 'styled-components'

import bg from "../../assets/img/bg-01.png";
const Box = styled.div`
	width:100%;
	padding:0 0 1rem 0;
	.top_fixed{
		width:100%;
		max-width:7.2rem;
		background: #EDEDED;
		position:fixed;
		top:0;
		z-index:100;
		.top{
			margin-top:0.15rem;
			font-size:0.24rem;
			font-weight: 550;
			margin-bottom:0.14rem;
			padding-left:0.2rem;
			>div{
				right:0.2rem;
			}
		}
		.menu{
			padding:0 0.2rem;
			.adm-dropdown{
				background: none;
				.adm-dropdown-nav{
					justify-content: space-between;
					padding-bottom: 0.14rem;
					.adm-dropdown-item{
						width: 0.89rem;
						background: #fff;
						flex: inherit;
						border-radius: 0.05rem;
						box-shadow: 0 0.01rem 0.05rem rgba(197,185,168,0.3);
						padding-right:0.1rem;
						.adm-dropdown-item-title{
							font-size:0.16rem;
						}
					}
					.adm-dropdown-item.select{
						background: #D6AC58;
						color: #fff;
						.adm-dropdown-item-title{
							&:after{
								border-color: transparent transparent #fff #fff;
							}
						}
					}
					.adm-dropdown-item.adm-dropdown-item-active{
						background: #D6AC58;
						color: #fff;
						.adm-dropdown-item-title{
							&:after{
								border-color: transparent transparent #fff #fff;
							}
						}
					}
				}
			}
		}
	}
	.list{
		margin-top:1.24rem;
	}
	@media (min-width: 750px){
		height:100vh;
		overflow-y:scroll;
		padding-bottom:1rem;
		&::-webkit-scrollbar{/*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
				width:14px;
				height:14px;
		}
		&::-webkit-scrollbar-button{/*滚动条两端的按钮，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置2）*/
				display:none;
		}
		&::-webkit-scrollbar-track{/*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-track-piece{/*内层轨道，滚动条中间部分（位置4）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-thumb{/*滚动条里面可以拖动的那部分（位置5）*/
			background:rgb(193,193,193);
		}
		&::-webkit-scrollbar-corner {/*边角（位置6）*/
			display:none;
		}
		&::-webkit-scrollbar-resizer  {/*定义右下角拖动块的样式（位置7）*/
			bdisplay:none;
		}
		.max-box{
			max-width:7.2rem;
			margin:0 auto 0 auto;
			.top_fixed{{
				.top{
					width:7.2rem;
					height:0.5rem;
					background:#fff;
					margin:0;
					display:flex;
					align-items:center;
					padding:0.1rem 0 0.1rem 0.2rem;
					z-index:100;
					padding:0 0.2rem;
					>div{
						right:0.2rem;
						top:0.1rem;
					}
				}
				.menu{
					background:#fff;
					padding-top:0.1rem;
					.adm-dropdown{
						max-width:7.2rem;
						margin:auto;
						.adm-dropdown-nav{
							margin-bottom:0;
							.adm-dropdown-item{
								box-shadow:none;
							}
						}
					}
					
				}
			}
			
			.list{
				margin-top:1.14rem;
			}
		}
	}
`

function Vip (props){
	const lng = window.localStorage.getItem('i18nextLng')
	const { t } = useTranslation();
	const [regionList, setRegionList] = useState([]) // 获取的区域列表
	const [roomType, setRoomType] = useState([]) // 获取的户型列表
	const [arr, setArr] = useState([]) // 获取的数据列表
	const priceList=[
		{label: t('list.priceListLabel0'),value:'1',minimumPrice: '0',maximumPrice:'500000'},
		{label: t('list.priceListLabel1'),value:'2',minimumPrice: '500000',maximumPrice:'750000'},
		{label: t('list.priceListLabel2'),value:'3',minimumPrice: '750000',maximumPrice:'1000000'},
		{label: t('list.priceListLabel3'),value:'4',minimumPrice: '1000000',maximumPrice:'1500000'},
		{label: t('list.priceListLabel4'),value:'5',minimumPrice: '1500000',maximumPrice:'2000000'},
		{label: t('list.priceListLabel5'),value:'6',minimumPrice: '2000000',maximumPrice:'2147483647'},
	]
	const [region, setRegion] = useState([]) // 选择的区域
	const [houseType, setHouseType] = useState([]) // 选择的户型
	const [price, setPrice] = useState([]) // 选择的价格区间
	const [sort, setSort] = useState(['DEFAULT']) // 选择排序方式
	
	// 是否选中变量
	const [regionSelect, setRegionSelect] = useState(false);
	const [priceSelect, setPriceSelect] = useState(false);
	const [houseTypeSelect, setHouseTypeSelect] = useState(false);
	const [sortSelect, setSortSelect] = useState(false);
	useEffect(() => {
		let title = document.getElementById('nav_title')
		let elng = window.localStorage.getItem('i18nextLng')
		if(elng === 'zh-CN'){
			title.innerText=`PresaleVIP.com 楼花王`
		}else{
			title.innerText=`PresaleVIP.com 樓花王`
		}
		// 区域获取
		post(`/wap/city/list`).then( res => {
			const list = []
			res.data.forEach((item)=>{
				list.push({'label':lng === 'zh-TW'?item.nameZhtw:lng === 'zh-CN'?item.nameZhcn:item.nameEn,'value':item.id})
			});
            list.push({ label: lng === 'en' || lng === 'EN' || lng === 'en-US' ? 'Other' : '其他', value: 'other' });
			setRegionList(list)
		})
		// 户型获取
		post(`/wap/sys/allStatus`,{
			key:'HOUSE_TYPE'
		}).then( res => {
				const list = []
				res.data.forEach((item)=>{
					list.push({'label':lng === 'zh-TW'?item.nameZhtw:lng === 'zh-CN'?item.nameZhcn:item.nameEn,'value':item.code})
				});
				setRoomType(list)
		})
		// 微信分享
		get(`/wechar/share/`,{
			url:window.location.href
		}).then( res => {
			window.wx.config({
			  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			  appId:  res.data.appId, // 必填，公众号的唯一标识
			  timestamp: res.data.timestamp, // 必填，生成签名的时间戳
			  nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
			  signature: res.data.signature,// 必填，签名，见附录1
			  jsApiList: [
				'updateAppMessageShareData',
				'updateTimelineShareData',
			  ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			})
		})
		// 判断是否微信浏览器
		const ua = window.navigator.userAgent.toLowerCase()
		if (ua.indexOf('micromessenger') < 0) return false
		// 开局调用分享
		window.wx.ready(() => {
			//分享给朋友
			window.wx.updateAppMessageShareData({
				title: '囊括大温各地区楼花转让 | 楼花王', // 分享标题
				desc: '第一选房 · 查看VIP价格 · 户型资料 · 特别优惠', // 分享描述
				link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: `https://www.presalevip.com${bg}`, // 分享图标
				success: function () {
				  // 用户确认分享后执行的回调函数
				},
				cancel: function () {
				  // 用户取消分享后执行的回调函数
				}
			})
			
			  //分享到朋友圈
			window.wx.updateTimelineShareData({
				title: '囊括大温各地区楼花转让 | 楼花王', // 分享标题
				link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: `https://www.presalevip.com${bg}`, // 分享图标
				success: function () {
				  // 用户确认分享后执行的回调函数
				},
				cancel: function () {
				  // 用户取消分享后执行的回调函数
				}
			})
		})
	}, []);
	function btnSubmit(e){
		setArr({
			houseType:houseType,
			sort:sort,
			price:price,
			region:region,
		})
		if(e === 1){
			if(region.length > 0){
				setRegionSelect(true)
			}else{
				setRegionSelect(false)
			}
		}else if(e === 2){
			if(price.length > 0){
				setPriceSelect(true)
			}else{
				setPriceSelect(false)
			}
		}else if(e === 3){
			if(houseType.length > 0){
				setHouseTypeSelect(true)
			}else{
				setHouseTypeSelect(false)
			}
		}else if(e === 4){
			if(sort.length > 0){
				setSortSelect(true)
			}else{
				setSortSelect(false)
			}
		}
	}
	return(
		<Box>
			<div className="max-box">
				<div className="top_fixed">
					<div className="top">
						<p>{t('list.HouseTransfer')}</p>
						<LngSet />
					</div>
					<div className="menu">
						<Dropdown>
							<Dropdown.Item key='region' title={t('list.Region')} 
								closeOnContentClick 
								forceRender 
								destroyOnClose
								className={regionSelect === true?'select':null}
							>
								<div onClick={(e)=>e.stopPropagation()}>
									<div className="title">{t('list.RegionSelection')}</div>
									<Selector
										options={regionList}
										multiple={true}
										onChange={(arr) => setRegion(arr)}
									/>
								</div>
								<div className="btn_ok" onClick={()=>btnSubmit(1)}>{t('list.Determine')}</div>
							</Dropdown.Item>
							
							<Dropdown.Item key='status' title={t('list.TotalPrice')} 
								closeOnContentClick 
								forceRender 
								destroyOnClose
								className={priceSelect === true?'select':null}
							>
								<div onClick={(e)=>e.stopPropagation()}>
									<div className="title">{t('list.PriceRange')}</div>
									<Selector
										options={priceList}
										multiple={true}
										onClick={(e)=>e.stopPropagation()}
										onChange={(arr, context) => {
											const list = [];
											context.items.forEach((items)=>{
												list.push({'minimumPrice':items.minimumPrice,'maximumPrice':items.maximumPrice})
											});
											setPrice(list)
										}
										}
									/>
								</div>
								<div className="btn_ok" onClick={()=>btnSubmit(2)}>{t('list.Determine')}</div>
							</Dropdown.Item>
							
							<Dropdown.Item key='price' title={t('list.houseType')} 
								closeOnContentClick 
								forceRender 
								destroyOnClose
								className={houseTypeSelect === true?'select':null}
							>
								<div onClick={(e)=>e.stopPropagation()}>
									<div className="title">{t('list.HouseTypeSelection')}</div>
									<Selector
										options={roomType}
										multiple={true}
										onChange={(arr, context) => setHouseType(arr)}
									/>
								</div>
								<div className="btn_ok" onClick={()=>btnSubmit(3)}>{t('list.Determine')}</div>
							</Dropdown.Item>
							
							<Dropdown.Item key='sort' title={t('list.Sort')} 
								closeOnContentClick 
								forceRender 
								destroyOnClose
								className={sortSelect === true?'select':null}
							>
								<CheckList 
									defaultValue={sort} 
									onChange={(e) =>setSort(e)}
								>
									<CheckList.Item onClick={(e)=>e.stopPropagation()} className={sort[0] === 'DEFAULT'?'cur':null} value="DEFAULT">{t('list.DefaultSort')}</CheckList.Item>
									<CheckList.Item onClick={(e)=>e.stopPropagation()} className={sort[0] === 'BUILT_YEAR_ASC'?'cur':null} value="BUILT_YEAR_ASC">{t('list.DeliveryNearToFar')}</CheckList.Item>
									<CheckList.Item onClick={(e)=>e.stopPropagation()} className={sort[0] === 'PRICE_ASC'?'cur':null} value="PRICE_ASC">{t('list.PriceFromLowToHigh')}</CheckList.Item>
									<CheckList.Item onClick={(e)=>e.stopPropagation()} className={sort[0] === 'PRICE_DESC'?'cur':null} value="PRICE_DESC">{t('list.PriceFromHighToLow')}</CheckList.Item>
								</CheckList>
								<div className="btn_ok" onClick={()=>btnSubmit(4)}>{t('list.Determine')}</div>
							</Dropdown.Item>
						</Dropdown>
					</div>
				</div>
				
				<Houselist arr={arr} />
			</div>
			<TabBar />
		</Box>
	)
}
export default withRouter(Vip)