import React,{useState,useEffect,useRef} from 'react'
import styled from 'styled-components'
import {Swiper,Image,ImageViewer,Dialog } from 'antd-mobile';
import Service from '../../components/Customer_service'
import Surroundings from '../../components/Surroundings'
import Hotquestion from '../../components/Hot_question'
import LngSet from '../../components/Lng_set'
import Tencent from '../../components/Tencent'
import YouTube from '../../components/YouTube'

import icon from "../../assets/img/icon-04.png";
import icon2 from "../../assets/img/icon-05.png";
import icon3 from "../../assets/img/icon-back.png";
import icon4 from "../../assets/img/icon-01.png";
import icon5 from "../../assets/img/icon-10.png";
import iconjt from "../../assets/img/icon-Arrows.svg";
import { post,get } from '../../service';
import { ImageURL } from '../../service/config';
import { useTranslation } from 'react-i18next';

const Box = styled.div`
	font-size:0.24rem;
	padding-bottom:1.2rem;
	.fixed_top{
		width:100%;
		max-width:7.2rem;
		position:fixed;
		top:0;
		z-index:100;
		.back{
			height:0.36rem;
			position: absolute;
			left:0.2rem;
			top:0.2rem;
			z-index:10;
			box-shadow: 0 0 0.06rem rgba(102,102,102,0.2);
			border-radius:0.09rem;
		}
		>div{
			top:0.2rem;
			right:0.2rem;
		}
	}
	.banner{
		width:100%;
		height:4.23rem;
		position: relative;
		.right{
			position: absolute;
			right: -50px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			display:none;
		}
		.left{
			position: absolute;
			left: -50px;
			top: 50%;
			transform: translateY(-50%) rotate(180deg);;
			cursor: pointer;
			display:none;
		}
		.adm-swiper{
			height:100%;
			.adm-swiper-indicator{
				transform: translateX(-50%);
				.adm-page-indicator{
					.adm-page-indicator-dot{
						width:0.14rem;
						height:0.08rem;
						border-radius:0.07rem;
						background:rgba(255,255,255,0.52);
						transition: all .2s;
					}
					.adm-page-indicator-dot-active{
						width:0.48rem;
						background:#fff;
					}
				}
			}
			.adm-swiper-track{
				height:100%;
				.adm-swiper-track-inner{
					width:100%;
					height:100%;
					.adm-swiper-slide{
						.adm-swiper-item{
							height:100%;
							position: relative;
							.video-bg{
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								cursor: pointer;
							}
							img{
								width:100%;
								height:100%;
							}
							img.play{
								width:0.7rem;
								height:0.5rem;
								position: absolute;
								left: 50%;
								top: 50%;
								transform: translate(-50%,-50%);
							}
						}
					}
				}
			}
		}
		.back{
			height:0.36rem;
			position: absolute;
			left:0.2rem;
			top:0.2rem;
            z-index: 10;
		}
		
		.banner_right{
			width:0.5rem;
			position: absolute;
			right:0.2rem;
			bottom:0.3rem;
			.banner_list{
				width:0.5rem;
				height:0.5rem;
				margin-bottom:0.05rem;
				border-radius:0.05rem;
				border:0.03rem solid #fff;
				overflow:hidden;
				position: relative;
				img{
					width:100%;
					height:100%;
				}
				.banner_list_num{
					position: absolute;
					color:#fff;
					font-size:0.18rem;
					top:50%;
					left:50%;
					transform: translate(-50%, -50%);
					display:none;
				}
			}
			.banner_list.cur{
				border-color:#D6AC58;
			}
		}
		.play{
			width:0.7rem;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}
	}
	.info{
		width:100%;
		padding:0 0.2rem;
		background:#fff;
		display:flex;
		flex-direction: column;
		box-shadow: 0 0.03rem 0.06rem rgba(158,151,138,0.16);
		.info_top{
			width:100%;
			display:flex;
			flex-direction: column;
			align-items:center;
			border-bottom:0.01rem solid #BFC5C9;
			padding:0.2rem 0 0.1rem 0;
			.info_title{
				font-size:0.24rem;
				line-height:0.33rem;
				font-weight: 550;
			}
			.info_addr{
				color:#666666;
				font-size:0.16rem;
				display:flex;
				align-items:center;
				img{
					height:0.15rem;
					margin-right:0.06rem;
				}
			}
			.info_tab{
				display:flex;
				align-items:center;
				font-size:0.14rem;
				color:#D6AC58;
				margin:0.12rem 0 0.08rem 0;
				p{
					height: 0.23rem;
					line-height: 0.23rem;
					padding: 0 0.1rem;
					margin:0 0.04rem;
					border:0.01rem solid #D6AC58;
					border-radius: 0.02rem;
				}
				p.info_tab_green{
					background:#58902D;
					color:#fff;
					border:0.01rem solid #58902D;
				}
				p.info_tab_yellow{
					background:#D6AC58;
					color:#fff;
					border:0.01rem solid #D6AC58;
				}
				p.info_tab_blue{
					background:#2D5590;
					color:#fff;
					border:0.01rem solid #2D5590;
				}
				p.info_tab_red{
					background:#C22A18;
					color:#fff;
					border:0.01rem solid #C22A18;
				}
                p.info_tab_grey{
                    background: #9C9C9C;
                    color: #fff;
                    border: 0.01rem solid #9C9C9C;
                }
			}
			.info_price{
				line-height:0.4rem;
				display:flex;
				align-items:baseline;
				font-size:0.16rem;
				color:#666;
				.price{
					font-size:0.28rem;
					font-weight: 550;
					color:#D6AC58;
					margin-right:0.06rem;
				}
			}
		}
		.info_bottom{
			padding:0.2rem 0 0.3rem 0;
			width:100%;
			display:flex;
			align-items:center;
			flex-wrap:wrap;
			font-size:0.16rem;
			justify-content: space-between;
			p{
				color:#333333;
				min-width:calc(50% - 0.05rem);
				min-height:0.36rem;
				line-height:0.3rem;
				display:flex;
				span{
					&:nth-last-child(1){
						flex:1;
					}
				}
			}
			p.cloum{
				width:100%;
				display:flex;
				span{
					&:nth-last-child(1){
						flex:1;
					}
				}
			}
		}
	}
	.list_title{
		display:flex;
		align-items:center;
		font-size:0.2rem;
		color:#000;
		margin: 0 0.2rem;
		padding:0.2rem 0;
		font-weight: 550;
		img{
			width:0.12rem;
			margin-right:0.053rem;
		}
	}
	.house_info{
		width:100%;
		background:#fff;
		padding-top:0.27rem;
		.house_type_img{
			width:100%;
			height:3.06rem;
			position: relative;
			.right{
				position: absolute;
				right: -50px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				display:none;
			}
			.left{
				position: absolute;
				left: -50px;
				top: 50%;
				transform: translateY(-50%) rotate(180deg);;
				cursor: pointer;
				display:none;
			}
			.adm-swiper{
				height:100%;
				.adm-swiper-track{
					height:100%;
					.adm-swiper-track-inner{
						height:100%;
						.adm-swiper-slide{
							.adm-swiper-item{
								height:100%;
								padding: 0 0.3rem 0.3rem 0.3rem;
								transition: all .2s;
								.item_img{
									width:100%;
									height:100%;
									box-shadow: 0 0.05rem 0.1rem rgba(0,0,0,0.15);
									font-size:0.18rem;
									display: flex;
									flex-direction: column;
									align-items: center;
									border-radius:0.05rem;
									overflow:hidden;
									.images{
										width:100%;
										height:70%;
									}
									.item_text{
										width:100%;
										height:30%;
										display:flex;
										flex-direction: column;
										align-items: center;
										justify-content: center;
										.s_price{
											color:#D6AC58;
										}
									}
								}
							}
							.adm-swiper-item.cur{
								padding: 0 0.1rem 0.1rem 0.1rem;
							}
						}
					}
				}
			}
		}
		.house_content{
			width:100%;
			display:flex;
			flex-direction: column;
			align-items:center;
			padding: 0 0.2rem 0.22rem 0.2rem;
			margin-top:0.3rem;
			.house_title{
				width:100%;
				font-size:0.2rem;
				font-weight: 550;
				color:#000;
			}
			.house_content-p{
				width:100%;
				display:flex;
				align-items:center;
				font-size:0.16rem;
				color:#18192D;
				margin:0.11rem 0 0.2rem 0;
				.house_content-p-1{
					font-weight: 550;
					margin-right:0.05rem;
                    white-space: pre-wrap;
				}
			}
			.house_text{
				width:100%;
				font-size:0.14rem;
				line-height:0.22rem;
				margin-top:0.13rem;
                p {
                    white-space: pre-wrap;
                }
				p.textover{
					height:1.1rem;
					display :-webkit-box;
					-webkit-box-orient: vertical;    
					-webkit-line-clamp: 5;
					overflow : hidden;
				}
			}
			.hosue_open{
                width: 100%;
				display:flex;
				align-items:center;
                justify-content: center;
				font-size:0.16rem;
				color:#90752D;
				margin-top:0.1rem;
				img{
					height:0.2rem;
					margin-left:0.1rem;
				}
				img.rotate{
					transform: rotate(180deg);
				}
			}
			.hosue_open.hide{
				display:none;
			}
		}
	}
	.gmnoprint{
		display:none;
	}
	@media (min-width: 750px){
		height:100vh;
		overflow-y:scroll;
		padding-bottom:1rem;
		&::-webkit-scrollbar{/*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
				width:14px;
				height:14px;
		}
		&::-webkit-scrollbar-button{/*滚动条两端的按钮，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置2）*/
				display:none;
		}
		&::-webkit-scrollbar-track{/*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-track-piece{/*内层轨道，滚动条中间部分（位置4）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-thumb{/*滚动条里面可以拖动的那部分（位置5）*/
			background:rgb(193,193,193);
		}
		&::-webkit-scrollbar-corner {/*边角（位置6）*/
			display:none;
		}
		&::-webkit-scrollbar-resizer  {/*定义右下角拖动块的样式（位置7）*/
			bdisplay:none;
		}
		.max-box{
			max-width:7.2rem;
			margin:0 auto 0 auto;
			.left,.right{
				display:block;
			}
			.house_info{
				.house_type_img{
					.left,.right{
						display:block;
					}
				}
			}
		}
	}
`
const text_over = {
	display :'-webkit-box',
	'-webkitBoxOrient' : 'vertical',
	"-webkitLineClamp" : '5',
	"overflow" : 'hidden',
}
const show_text ={
	display:"block"
}
const hide = {
    display: 'none'
}
function queryURLParameter(url){
	let reg = /([^?&=]+)=([^?&=]+)/g
	let obj = {}
	url.replace(reg,function(){
		obj[arguments[1]] = arguments[2]
	})
	return obj
}
function OverlayVisible(props) {
    let { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [cur, setCur] = useState(0);
    const [building, setBuilding] = useState();
    const [list, setList] = useState([]);
    const lng = window.localStorage.getItem('i18nextLng')
	const [video, setVideo] = useState(false)
	const [rightImg, setRightImg] = useState();
	const [imageIndex, setImageIndex] = useState(1);
	const [visible, setVisible] = useState(false)
	const [imageView, setImageView] = useState();
	const [bannerImg, setBannerImg] = useState()
	const [info, setInfo] = useState([])
	const [isShow, setIsShow] = useState(false)
	
	const ref = useRef()
	const houseRef = useRef()
	const imgRef = useRef()
	const textRef = useRef()
	const Multi = () => {
	  return (
	    <>
	      <ImageViewer.Multi
	        visible={visible}
	        onClose={() => {
	          setVisible(false)
	        }}
	      />
	    </>
	  )
	}
    useEffect(() => {
		let title = document.getElementById('nav_title')
		let elng = window.localStorage.getItem('i18nextLng')
		if(elng === 'zh-CN'){
			title.innerText=`PresaleVIP.com 楼花王`
		}else{
			title.innerText=`PresaleVIP.com 樓花王`
		}
        post(`/wap/building/queryBuildingDetail`, {
            id: props.match.params.id,
            language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`
        }).then((res) => {
			if(res.code === 0){
				// 将\n替换为换行
				// res.data.downPaymentRate = res.data.downPaymentRate.replace(/\n/g, '<br />');
				// 处理楼盘状态的样式名称
				if (res.data.status === 'Sold Out' || res.data.status === '售罄' || res.data.status === '售罄') {
				    res.data.statusClassName = 'info_tab_grey';
				} else if (res.data.status === 'Transafer' || res.data.status === '转让' || res.data.status === '轉讓') {
				    res.data.statusClassName = 'info_tab_red';
				} else if (res.data.status === 'On Sale' || res.data.status === '在售' || res.data.status === '在售') {
				    res.data.statusClassName = 'info_tab_yellow';
				} else if (res.data.status === 'Register' || res.data.status === '注册' || res.data.status === '註冊') {
				    res.data.statusClassName = 'info_tab_green';
				} else if (res.data.status === 'Ending' || res.data.status === '尾盘' || res.data.status === '尾盤') {
				    res.data.statusClassName = 'info_tab_blue';
				} else {
				    res.data.statusClassName = 'info_tab_green';
				}
				setBuilding(res.data);
				setList(res.data?.detailPicList);
				if(document.getElementById('textover').scrollHeight > document.getElementById('textover').offsetHeight){
					setIsShow(true)
				}
				if(res.data.tencentUrl !== ''){
					if(res.data.tencentUrl !== null){
						if(lng === 'zh-CN'){
							setVideo(true)
						}
					}
				}
				if(res.data.youtubeUrl !== ''){
					if(res.data.youtubeUrl !== null){
						if(lng !== 'zh-CN'){
							setVideo(true)
						}
					}
				}
				var list = []
				res.data.houseTypeDetails.forEach((item)=>{
					list.push(item.pic)
				});
				var blist = []
				res.data?.detailPicList.forEach((item)=>{
					blist.push(item)
				});
				setBannerImg(blist)
				setImageView(list)
				// 微信分享
				get(`/wechar/share/`,{
					url:window.location.href
				}).then( res => {
					window.wx.config({
					  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
					  appId:  res.data.appId, // 必填，公众号的唯一标识
					  timestamp: res.data.timestamp, // 必填，生成签名的时间戳
					  nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
					  signature: res.data.signature,// 必填，签名，见附录1
					  jsApiList: [
					    'updateAppMessageShareData',
					    'updateTimelineShareData',
					  ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
					})
				})
				// 判断是否微信浏览器
				const ua = window.navigator.userAgent.toLowerCase()
				if (ua.indexOf('micromessenger') < 0) return false
				// 开局调用分享
				window.wx.ready(() => {
					//分享给朋友
					window.wx.updateAppMessageShareData({
						title: `${res.data.city} 楼花 ${res.data.name} | 楼花王VIP通道登记/开放中`, // 分享标题
						desc: `${res.data.name} by ${res.data.builder} - 查看VIP价格 · 户型资料 · 特别优惠`, // 分享描述
						link: window.location.href+'?share=true', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: res.data.coverPic, // 分享图标
						success: function () {
						  // 用户确认分享后执行的回调函数
						},
						cancel: function () {
						  // 用户取消分享后执行的回调函数
						}
					})
					
					  //分享到朋友圈
					window.wx.updateTimelineShareData({
						title: `${res.data.city} 楼花 ${res.data.name} | 楼花王VIP通道登记/开放中`, // 分享标题
						link: window.location.href+'?share=true', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: res.data.coverPic, // 分享图标
						success: function () {
						  // 用户确认分享后执行的回调函数
						},
						cancel: function () {
						  // 用户取消分享后执行的回调函数
						}
					})
				})
			}else{
				Dialog.alert({
					content: (<p style={{textAlign:'center'}}>{t('msg.houseMsg')}</p>),
					confirmText:t('VIP.confirmButtonText'),
					onConfirm: () => {
						if(queryURLParameter(window.location.href).share === 'true'){
							props.history.push(`/`)
							console.log('来源---分享')
						}else{
							props.history.goBack(-1)
							console.log('来源---列表')
						}
					},
				})
			}
            
        });
		
    }, []);
	function setText(){
		if(show === true){
			setShow(false)
		}else{
			setShow(true)
		}
	}
	
    return (
		<Box>
			<div className="max-box">
				{
				<div className="banner">
					<div className="fixed_top">
						<img className="back" src={icon3} alt="back" 
							onClick={
								()=>{
									// if(queryURLParameter(window.location.href).share === 'true'){
									if(props.location.state?.ishistory){
										props.history.goBack(-1)
									}else{
										props.history.push(`/`)
									}
								}
							} 
						/>
						<LngSet />
					</div>
					<div className="left"
						onClick={() => {
							ref.current?.swipePrev()
						}}
					><img src={iconjt} /></div>
					<Swiper 
						autoplay
						onIndexChange={
							(e)=>{
								setCur(e)
								if(e > 2){
									setRightImg(list[e])
								}
							}
						}
						autoplayInterval={5000}
						ref={ref}
						loop={true}
						indicatorProps={{
							color: 'white',
						}}
					>
					{
						video === true?<Swiper.Item
							style={video === false ?hide:null}
							onClick={() => {
								props.history.push(
									{pathname:'/details_video'},
									{cnLink:building.tencentUrl,enLink:building.youtubeUrl},
								)
							}}
						>
							{
								lng === 'zh-CN'?
								<Tencent link={building.tencentUrl}></Tencent>:
								<YouTube link={building.youtubeUrl}></YouTube>
							}
							<div className="video-bg"></div>
						</Swiper.Item>:null
					}
						
					{
						list.map((item, index) => (
						  <Swiper.Item key={index}>
								<Image lazy src={`${item}`} width={`100%`} height={`100%`} fit='cover'
									onClick={() => {
										ImageViewer.Multi.show({images: bannerImg,defaultIndex:index})
									}}
								/>
						  </Swiper.Item>
						))
					}
					</Swiper>
					<div className="right"
						onClick={() => {
							ref.current?.swipeNext()
						}}
					><img src={iconjt} /></div>
					<div className="banner_right">
						<div className={cur===0 && video === false?'cur banner_list':cur===1 && video === true?'cur banner_list':'banner_list'} key='0'
							onClick={() => {
								if( video === true){
									ref.current?.swipeTo(1)
								}else{
									ref.current?.swipeTo(0)
								}
								
							}}
							style={list.length > 0?show_text:hide}
						>
							<Image lazy src={`${list[0]}`} width={`100%`} height={`100%`} fit='cover' />
						</div>
						<div className={cur===1 && video === false?'cur banner_list':cur===2 && video === true?'cur banner_list':'banner_list'} key='1'
							onClick={() => {
								if( video === true){
									ref.current?.swipeTo(2)
								}else{
									ref.current?.swipeTo(1)
								}
							}}
							style={list.length > 1?show_text:hide}
						>
							<Image lazy src={`${list[1]}`} width={`100%`} height={`100%`} fit='cover' />
						</div>
						<div className={cur > 1 && video === false?'cur banner_list':cur > 2 && video === true?'cur banner_list':'banner_list'} key='2'
							onClick={() => {
								if( video === true){
									ref.current?.swipeTo(3)
								}else{
									ref.current?.swipeTo(2)
								}
							}}
							style={list.length > 2?show_text:hide}
						>
							<Image lazy src={cur > 2 && video === false ? rightImg:cur > 3 && video === true?rightImg:`${list[2]}`} width={`100%`} height={`100%`} fit='cover' />
							<p style={list.length > 2?show_text:null} className="banner_list_num">+{list.length}</p>
						</div>
					</div>
				</div>
				}
				
				<div className="info">
					<div className="info_top">
				        <p className="info_title">{building?.name}</p>
						<div className="info_addr">
							<img src={icon2} alt="icon" />
				            <p>{building?.address}</p>
						</div>
						<div className="info_tab">
				            <p className={building?.statusClassName}>{building?.status}</p>
				            <p>{building?.type}</p>
				            <p>{building?.builtYear}{window.localStorage.getItem('i18nextLng' === 'en' ? '' : '建成')}</p>
						</div>
						{/* <div className="info_price">
						{
							building?.minimumPrice === '0' || building?.minimumPrice === '0.0' || building?.minimumPrice === '0.00'?
							<p className="price">N/A</p>
							:
							<p className="price">${building?.minimumPrice}{t('DetailVIP.minimumPriceLabel')}</p>
						}
						</div> */}
					</div>
					<div className="info_bottom" style={building?.houseTypeDetails === null || building?.houseTypeDetails === [] || building?.houseTypeDetails?.length <= 0 ? {paddingBottom: 0} : null}>
						<p style={building?.city === null || building?.city === '' ? hide : null}><span>{t('DetailVIP.city')}：</span><span>{building?.city}</span></p>
						<p style={building?.communityName === null || building?.communityName === '' ? hide : null} hidden={!(building?.communityName)}><span>{t('DetailVIP.communityName')}：</span><span>{building?.communityName}</span></p>
						<p style={building?.minimumArea === null || building?.minimumArea === '' || building?.maximumArea === null || building?.maximumArea === '' ? hide : null}><span>{t('DetailVIP.area')}：</span><span>{building?.minimumArea}-{building?.maximumArea}{t('DetailVIP.ft')}</span></p>
						<p style={building?.totalFloor === null || building?.totalFloor === 0 ? hide : null}><span>{t('DetailVIP.totalFloor')}：</span><span>{building?.totalFloor}{t('DetailVIP.floor')}</span></p>
						<p style={building?.totalHouse === null || building?.totalHouse === 0 ? hide : null}><span>{t('DetailVIP.unit')}：</span><span>{building?.totalHouse}{t('DetailVIP.houseUnit')}</span></p>
						<p style={building?.builtYear === null || building?.builtYear === 0 ? hide : null}><span>{t('DetailVIP.builtYear')}：</span><span>{building?.builtYear}</span></p>
						<p style={building?.managementFee === null || building?.managementFee === '' ? hide : null}><span>{t('DetailVIP.managementFee')}：</span><span>${building?.managementFee}/{t('DetailVIP.ft')}</span></p>
						<p style={building?.assignmentFee === null || building?.assignmentFee === '' ? hide : null}>{t('details.assignmentFee')}：<span>{building?.assignmentFee}</span></p>
						<p style={building?.builder === null || building?.builder === '' ? hide : null}><span>{t('DetailVIP.builder')}：</span><span>{building?.builder}</span></p>
						<p style={building?.propertyType === null || building?.propertyType === '' ? hide : null}><span>{t('DetailVIP.propertyType')}：</span><span>{building?.propertyType}</span></p>
						<p style={building?.lastModifyDate === null || building?.lastModifyDate === '' ? hide : null}><span>{t('DetailVIP.lastUpdatedDate')}：</span><span>{building?.lastModifyDate}</span></p>
						<p><span>{t('DetailVIP.ProjectStartingPrice')}：</span><span>{(building?.minimumPrice === '0' || building?.minimumPrice === '0.0' || building?.minimumPrice === '0.00')?'N/A':`$${building?.minimumPrice}${t('DetailVIP.minimumPriceLabel')}`}</span></p>
						<p className="cloum" style={building?.facility === null || building?.facility === '' ? hide : null}><span>{t('DetailVIP.facility')}：</span><span>{building?.facility}</span></p>
				</div>
				</div>
				
				<div className="list_title" style={building?.houseTypeDetails === null || building?.houseTypeDetails === [] || building?.houseTypeDetails?.length <= 0 ? hide : null}>
					<img className="list_icon" src={icon4} alt='icon' />
							<p>{t('DetailVIP.houseType')} (<span>{building?.houseTypeDetails?.length}</span>)</p>
				</div>
						
				<div className="house_info" style={
					(building?.houseTypeDetails === null || building?.houseTypeDetails === [] || building?.houseTypeDetails?.length <= 0) &&
					(building?.downPaymentRate === null || building?.downPaymentRate === '') &&
					(building?.description === null || building?.description === '') ? hide : null
				}>
					<div className="house_type_img" style={building?.houseTypeDetails === null || building?.houseTypeDetails === [] || building?.houseTypeDetails?.length <= 0 ? hide : null}>
						<div className="left"
							onClick={() => {
								houseRef.current?.swipePrev()
							}}
						><img src={iconjt} /></div>
						<Swiper
							defaultIndex={1}
							indicator={() => null}
							stuckAtBoundary={false}
							loop={false}
							ref={houseRef}
							slideSize={60}
							style={{
								'--track-offset':'20%',
								'--slide-width':'60%'
							}}
							onIndexChange={
								(e)=>{
									setImageIndex(e)
								}
							}
						>
						{
							building?.houseTypeDetails.map((item, index) => (
								<Swiper.Item key={index}  className={imageIndex === index?'cur':null}
									onClick={(e)=>{
										setVisible(true)
										setImageIndex(index)
										ImageViewer.Multi.show({images: imageView,defaultIndex:index})
										houseRef.current?.swipeTo(index)
									}}
								>
									<div className="item_img">
										<Image className="images" src={`${item.pic}`} width={`100%`} height={`100%`} fit='cover' />
										<div className="item_text">
											<p className="s_title">{item.name}</p>
											{
												item.price === '0' || item.price === '0.00' || item.price === '0.0' ?
												<p className="s_price">{lng === 'en' ? null : lng === 'zh-CN' ? '起价：' : '起價：'}N/A</p>
												:
												<p className="s_price">
												    {lng === 'en' ? null : lng === 'zh-CN' ? '起价：' : '起價：'}${item.price}{lng === 'en' ? null : lng === 'zh-CN' ? '万' : '萬'}
												</p>
											}
										</div>
									</div>
								</Swiper.Item>
							))
						}
						</Swiper>
						<div className="right"
							onClick={() => {
								houseRef.current?.swipeNext()
							}}
						><img src={iconjt} /></div>
					</div>
					<div className="house_content" style={building?.houseTypeDetails === null || building?.houseTypeDetails === [] || building?.houseTypeDetails?.length <= 0 ? {marginTop:'0'} : null}>
						<p className="house_title" style={building?.downPaymentRate === null || building?.downPaymentRate === '' ? hide : null}>{t('DetailVIP.downPaymentRate')}</p>
						<div className="house_content-p" style={building?.downPaymentRate === null || building?.downPaymentRate === '' ? hide : null}>
							<p className="house_content-p-1">{building?.downPaymentRate}</p>
						</div>
						<p className="house_title" style={building?.description === null || building?.description === '' ? hide : null}>{t('DetailVIP.description')}</p>
						<div className="house_text" style={building?.description === null || building?.description === '' ? hide : null}>
							<p id="textover" ref={textRef} className={show === false ? 'textover' : null}>
								{building?.description}
							</p>
						</div>
						<div className={isShow?'hosue_open':'hosue_open hide'} onClick={()=>setText()} style={building?.description === null || building?.description === '' ? hide : null}>
							<p>{show=== false?t('DetailVIP.expand'):t('DetailVIP.collapse')}</p>
							<img className={show === true?'rotate':null} src={icon5} alt="icon" />
						</div>
					</div>
				</div>
				<div className="list_title">
					<img className="list_icon" src={icon4} alt='icon' />
					<p>{t('DetailVIP.nearbyAndLocation')}</p>
				</div>
				<Surroundings
				    location={{
				        lat: parseFloat(building?.latitude) ? parseFloat(building?.latitude) : 49.24913249999999,
				        lng: parseFloat(building?.longitude) ? parseFloat(building?.longitude) : -123.1056808
				    }}
				    nearbyDetailMap={building?.nearbyDetailMap ? building.nearbyDetailMap : { "PublicTransportation": [], "ShoppingMall": [], "Hospital": [] }} />
				<Service type={1} houseID={props.match.params.id} />
				<div className="list_title">
					<img className="list_icon" src={icon4} alt='icon' />
					<p>{t('DetailVIP.faq')}</p>
				</div>
				<Hotquestion />
			</div>
			
		</Box>
    );
}
export default OverlayVisible