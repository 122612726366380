import React,{useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'
import { CheckList,Dropdown, Slider,Selector,Image } from 'antd-mobile';
import TabBar from '../../components/Tabbar'
import Map from '../../components/Map/Marker'
import { useTranslation } from 'react-i18next';
import I18n from "../../I18next";
import LngSet from '../../components/Lng_set'
import { ImageURL } from '../../service/config';
import { post,get } from '../../service';

import icon from "../../assets/img/icon-03.png";
import bg from "../../assets/img/bg-01.png";
import close from "../../assets/img/icon-close.svg";
const Box = styled.div`
	font-size:0.24rem;
	padding-bottom:0.8rem;
	.gmnoprint{
		display:none;
	}
	.top{
		margin-top:0.15rem;
		font-size:0.24rem;
		font-weight: 550;
		margin-bottom:0.14rem;
		padding:0 0.2rem;
		display:flex;
		align-items:center;
		justify-content: space-between;
		.top_select{
			display:flex;
			align-items:center;
			font-size:0.14rem;
			cursor: pointer;
			p{
				color:#666666;
				margin-right:0.1rem;
			}
			p.cur{
				color:#D6AC58;
				font-weight: 550;
			}
			.set_lng{
				>div{
					position: relative;
					right:0;
					top:0;
					padding:0;
					.lng{
						.lng-list{
							position: absolute;
							top: 0.4rem;
						}
					}
				}
			}
		}
	}
	.adm-dropdown{
		background: none;
		padding:0 0.2rem;
		position: relative;
		z-index:1;
		.adm-dropdown-nav{
			justify-content: space-between;
			.adm-dropdown-item{
				width: 0.89rem;
				height:0.4rem;
				background: #fff;
				flex: inherit;
				border-radius: 0.05rem;
				box-shadow: 0 0.01rem 0.05rem rgba(197,185,168,0.3);
				padding-right:0.1rem;
				.adm-dropdown-item-title{
					font-size:0.16rem;
					display:flex;
					align-items:center;
				}
			}
			.adm-dropdown-item.select{
				background: #D6AC58;
				color: #fff;
				.adm-dropdown-item-title{
					&:after{
						border-color: transparent transparent #fff #fff;
					}
				}
			}
			.adm-dropdown-item.adm-dropdown-item-active{
				background: #D6AC58;
				color: #fff;
				.adm-dropdown-item-title{
					&:after{
						border-color: transparent transparent #fff #fff;
					}
				}
			}
		}
	}
	.map_box{
		margin-top:-0.05rem;
		position: relative;
		width:100%;
		.close{
			position: absolute;
			height:0.4rem;
			right: 0;
			bottom: 2.5rem;
			display:none;
		}
		.house_list{
			position: absolute;
			left:0.2rem;
			bottom:0.3rem;
			width:calc(100% - 0.2rem);
			overflow-x:scroll;
			.house_ul{
				width:100%;
				height:2.2rem;
				display:flex;
				align-items:center;
				.house_li{
					width:3rem;
					height:2rem;
					display:flex;
					border-radius:0.07rem;
					overflow:hidden;
					background:#fff;
					box-shadow: 0 0.05rem 0.1rem rgba(102,102,102,0.3);
					margin-right:0.2rem;
					flex-shrink: 0;
					.house_img{
						width:1.6rem;
						height:100%;
						overflow:hidden;
						display:flex;
						align-items:center;
						justify-content: center;
					}
					.house_text{
						width:calc(100% - 1.6rem);
						height:100%;
						display:flex;
						padding:0.18rem 0.12rem 0.23rem 0.12rem;
						flex-direction: column;
						justify-content: space-between;
						.text_top{
							width:100%;
							display:flex;
							flex-direction: column;
							align-items:flex-start;
							.top_title{
								width:100%;
								color:#000;
								font-size:0.16rem;
								font-weight: 550;
								overflow: hidden;
								text-overflow: ellipsis;
								word-spacing: normal;
							}
							.top_time{
								font-size:0.12rem;
								color:#666;
								margin-top:0.04rem;
							}
							.top_addr{
								background:#D6AC58;
								border-radius:0.03rem;
								padding:0.02rem 0.06rem;
								font-size:0.12rem;
								color:#fff;
								margin-top:0.04rem;
							}
						}
						.text_bottom{
							width:100%;
							display:flex;
							align-items:center;
							justify-content: space-between;
							.text_price{
								font-weight: 550;
								font-size:0.12rem;
								display:flex;
								align-items:center;
								flex:1;
								margin-right:0.1rem;
								.price{
									font-size:0.18rem;
									width:100%;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									flex:1;
								}
							}
							img{
								height:0.11rem;
							}
						}
					}
				}
			}
		}
	}
	@media (min-width: 750px){
		.max-box{
			max-width:7.2rem;
			margin:0 auto 0 auto;
			.top{
				background:#fff;
				margin:0;
				width:100%;
				display:flex;
				align-items:center;
				padding:0.1rem 0.2rem;
			}
			.adm-dropdown{
				background:#fff;
				width:100%;
				z-index:10;
				.adm-dropdown-nav{
					margin-bottom:0;
					.adm-dropdown-item{
						box-shadow:none;
					}
				}
			}
			.close{
				bottom:2.7rem;
				right:0.2rem;
			}
		}
	}
`

function OverlayVisible(props) {
	let {t, i18n} = useTranslation()
	const priceList=[{label: window.localStorage.getItem('i18nextLng') === 'en' ? 'All' : '不限', value: 'ALL'}]
	const img = require("../../assets/img/image-01.png");
	const [cur, setCur] = useState('building')
    const [presaleTypes, setPresaleTypes] = useState([]);
    const [houseTypes, setHouseTypes] = useState([]);
    const [selectedPresaleTypes, setSelectedPresaleTypes] = useState([]);
    const [selectedBedroom, setSelectedBedroom] = useState([]);
    const [selectedPrices, setSelectedPrices] = useState([]);
    const [url, setUrl] = useState('/wap/map/searchBuilding');
    const [detailTo, setDetailTo] = useState('/details_vip')
    const [allPrice, setAllPrice] = useState(false);
	const [statusList, setStatusList] = useState([]);
	const [status, setStatus] = useState([]);
    // 记录前一次选中的加个区间，目的是：当用户先选择了价格区间，再选择不限
    // 在选择不限制后，又去掉不限，这样会丢失之前选择的价格区间，使用这个变量把之前选择的价格区间保存起来
    const [preSelectedPrices, setPreSelectedPrices] = useState([]);
	
	// 是否选中变量
	const [typeSelect, setTypeSelect] = useState(false);
	const [houseSelect, setHouseSelect] = useState(false);
	const [priceSelect, setPriceSelect] = useState(false);
	const [statusSelect, setStatusSelect] = useState([]);
    // 获取到所有需要聚合展示的数据 从后台拿
    const [mapShowList, setMapShowList] = useState([]);
    // 展示在下方可以滑动查看的数据
    const [swiperData, setSwiperData] = useState([]);
	
	// 关闭列表
	const [listClose, setListClose] = useState(false);
    const marks = {
        0: t('MapHousePage.priceMarks0'),
        10: t('MapHousePage.priceMarks1'),
        22: t('MapHousePage.priceMarks2'),
        36: t('MapHousePage.priceMarks3'),
        52: t('MapHousePage.priceMarks4'),
        68: t('MapHousePage.priceMarks5'),
        82: t('MapHousePage.priceMarks6'),
        100: t('MapHousePage.priceMarks7')
    }
    const priceRange = { 0: 0, 10: 500000, 22: 1000000, 36: 1500000, 52: 2000000, 68: 5000000, 82: 7500000, 100: 10000000 };
    let refCur = useRef();
    let refUrl = useRef();
    let refDetailTo = useRef();
    let refSelectedPresaleTypes = useRef();
    let refSelectedBedroom = useRef();
    let refSelectedPrices = useRef();
    // 获取页面初始化数据
    useEffect(() => {
        var locale = window.localStorage.getItem('i18nextLng');
		let title = document.getElementById('nav_title')
		let elng = window.localStorage.getItem('i18nextLng')
		if(elng === 'zh-CN'){
			title.innerText=`PresaleVIP.com 楼花王`
		}else{
			title.innerText=`PresaleVIP.com 樓花王`
		}
        // 楼盘或楼花类型
        post(`/wap/sys/allStatus`, {
            key: cur === 'building' ? "BUILDING_TYPE" : "PRESALE_TYPE"
        }).then((res) => {
            if (res && res?.data?.length > 0) {
                var label = '';
                var tempTypes = [];
                if (locale === 'en') {
                    tempTypes.push({ label: 'All', value: 'ALL' });
                } else {
                    tempTypes.push({ label: '不限', value: 'ALL' });
                }
                for (let i = 0; i < res.data.length; ++i) {
                    if (locale === 'en') {
                        label = res.data[i].nameEn;
                    } else if (locale === 'zh-TW') {
                        label = res.data[i].nameZhtw;
                    } else {
                        label = res.data[i].nameZhcn;
                    }
                    tempTypes.push({ label: label, value: res.data[i].code });
                }
                setPresaleTypes(tempTypes);
            }
        });
        // 户型
        post(`/wap/sys/allStatus`, {
            key: "HOUSE_TYPE"
        }).then((res) => {
            if (res && res?.data?.length > 0) {
                var label = '';
                var tempHouseTypes = [{ label: window.localStorage.getItem('i18nextLng') === 'en' ? 'ALL' : '不限', value: 'ALL' }];
                for (let i = 0; i < res.data.length; ++i) {
                    if (locale === 'en') {
                        label = res.data[i].nameEn;
                    } else if (locale === 'zh-TW') {
                        label = res.data[i].nameZhtw;
                    } else {
                        label = res.data[i].nameZhcn;
                    }
                    tempHouseTypes.push({ label: label, value: res.data[i].code });
                }
                setHouseTypes(tempHouseTypes);
            }
        });
		// 状态
		post(`/wap/sys/allStatus`, {
		    key: "BUILDING_STATUS"
		}).then((res) => {
		    if (res && res?.data?.length > 0) {
		        var locale = window.localStorage.getItem('i18nextLng');
		        var label = '';
		        var tempStatusList = [{ label: window.localStorage.getItem('i18nextLng') === 'en' ? 'ALL' : '不限', value: 'ALL' }];
		        for (let i = 0; i < res.data.length; ++i) {
		            if (locale === 'en' || locale === 'EN') {
		                label = res.data[i].nameEn;
		            } else if (locale === 'zh-TW' || locale === 'ZHTW') {
		                label = res.data[i].nameZhtw;
		            } else {
		                label = res.data[i].nameZhcn;
		            }
		            tempStatusList.push({ label: label, value: res.data[i].code });
		        }
		        setStatusList(tempStatusList);
		    }
		})
    }, [cur]);

    useEffect(() => {
        var locale = window.localStorage.getItem('i18nextLng');
        // 初始化页面楼花或楼盘数据
        post(url, {
            language: `${locale === 'zh-CN' ? 'ZHCN' : locale === 'zh-TW' ? 'ZHTW' : 'EN'}`
        }).then((res) => {
            if (res && res?.data?.length > 0) {
                setMapShowList(res.data);
            }
        });
		// 微信分享
		get(`/wechar/share/`,{
			url:window.location.href
		}).then( res => {
			window.wx.config({
			  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			  appId:  res.data.appId, // 必填，公众号的唯一标识
			  timestamp: res.data.timestamp, // 必填，生成签名的时间戳
			  nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
			  signature: res.data.signature,// 必填，签名，见附录1
			  jsApiList: [
				'updateAppMessageShareData',
				'updateTimelineShareData',
			  ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			})
		})
		// 判断是否微信浏览器
		const ua = window.navigator.userAgent.toLowerCase()
		if (ua.indexOf('micromessenger') < 0) return false
		// 开局调用分享
		window.wx.ready(() => {
			//分享给朋友
			window.wx.updateAppMessageShareData({
				title: '大温楼花 地图清晰一键查找 | 楼花王', // 分享标题
				desc: '第一选房 · 查看VIP价格 · 户型资料 · 特别优惠', // 分享描述
				link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: `https://www.presalevip.com${bg}`, // 分享图标
				success: function () {
				  // 用户确认分享后执行的回调函数
				},
				cancel: function () {
				  // 用户取消分享后执行的回调函数
				}
			})
			
			  //分享到朋友圈
			window.wx.updateTimelineShareData({
				title: '大温楼花 地图清晰一键查找 | 楼花王', // 分享标题
				link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: `https://www.presalevip.com${bg}`, // 分享图标
				success: function () {
				  // 用户确认分享后执行的回调函数
				},
				cancel: function () {
				  // 用户取消分享后执行的回调函数
				}
			})
		})
    }, []);

    /**
     * 条件筛选
     */
    function mapQuery(e) {
        var minimumPrice;
        var maximumPrice;
        var selectedAllPresaleTypes = false;
        var selectedAllBedroom = false;
        if (selectedPrices === null || selectedPrices?.length === 0) {
            minimumPrice = null;
            maximumPrice = null;
        } else {
            minimumPrice = priceRange[selectedPrices[0]];
            maximumPrice = priceRange[selectedPrices[1]];
        }
        if (minimumPrice === maximumPrice) {
            minimumPrice = null;
            maximumPrice = null;
        }
		if(e === 1){
			if(selectedPresaleTypes?.length > 0 || selectedPresaleTypes?.length === undefined){
				setTypeSelect(true)
			}else{
				setTypeSelect(false)
			}
		}else if(e === 2){
			if(status?.length > 0 || status?.length === undefined){
				setStatusSelect(true)
			}else{
				setStatusSelect(false)
			}
		}else if(e === 3){
			if(selectedBedroom?.length > 0 || selectedBedroom?.length === undefined){
				setHouseSelect(true)
			}else{
				setHouseSelect(false)
			}
		}else if(e === 4){
			if(selectedPrices?.length > 0 || selectedPrices?.length === undefined){
				setPriceSelect(true)
			}else{
				setPriceSelect(false)
			}
        }
        for (let i = 0; i < selectedPresaleTypes.length; ++i) {
            if (selectedPresaleTypes[i] === 'ALL') {
                selectedAllPresaleTypes = true;
                break;
            }
        }
        for (let i = 0; i < selectedBedroom.length; ++i) {
            if (selectedBedroom[i] === 'ALL') {
                selectedAllBedroom = true;
                break;
            }
        }
        post(refUrl.current, {
            language: window.localStorage.getItem('i18nextLng'),
            types: selectedAllPresaleTypes ? null : selectedPresaleTypes,
            houseTypes: selectedAllBedroom ? null : selectedBedroom,
            minimumPrice: minimumPrice,
            maximumPrice: maximumPrice,
			status:status
        }).then((res) => {
            if (res && res?.data?.length >= 0) {
                setMapShowList(res.data);
            }
        });
    }

    /**
     * 获取点击的聚合点的数据，从子组件传过来
     */
    function getListDataFromMap(params) {
        setSwiperData(params);
		if(params >10){
			setListClose(false)
		}else{
			setListClose(true)
		}
    }

    useEffect(() => {
        refCur.current = cur;
        refUrl.current = url;
        refDetailTo.current = detailTo;
    }, [cur, url, detailTo]);
	return (
		<Box>
			<div className="max-box">
				<div className="top">
					<p>{t('MapHousePage.presaleVIP')}</p>
					<div className="top_select">
						<p
							className={cur === 'building' ? 'cur' : ''}
							onClick={() => {
								Promise.resolve().then(() => {
									setSelectedPrices([]);
                                    // setSelectedPrices([0, 0]);
									setSelectedBedroom([]);
									setSelectedPresaleTypes([]);
									setSwiperData([]);
									setTypeSelect(false);
									setHouseSelect(false);
									setPriceSelect(false);
									setCur('building')
									setUrl('/wap/map/searchBuilding')
									setDetailTo('/details_vip')
								}).then(() => {
									// mapQuery();
									post('/wap/map/searchBuilding', {
										language: window.localStorage.getItem('i18nextLng')
									}).then((res) => {
										if (res && res?.data?.length > 0) {
											setMapShowList(res.data);
										}
									})
								})
							}}>
							{t('MapHousePage.presaleVIPTabbar')}
						</p>
						<p
							className={cur === 'house' ? 'cur' : ''}
							onClick={() => {
								Promise.resolve().then(() => {
									setSelectedPrices([]);
                                    // setSelectedPrices([0, 0]);
									setSelectedBedroom([]);
									setSelectedPresaleTypes([]);
									setSwiperData([]);
									setTypeSelect(false);
									setHouseSelect(false);
									setPriceSelect(false);
									setCur('house')
									setUrl('/wap/map/searchHouse')
									setDetailTo("/details_transfer")
									var temp = houseTypes;
									setHouseTypes(temp);
								}).then(() => {
									// mapQuery();
									post('/wap/map/searchHouse', {
										language: window.localStorage.getItem('i18nextLng')
									}).then((res) => {
										if (res && res?.data?.length > 0) {
											setMapShowList(res.data);
										}
									})
								})
							}}>
							{t('MapHousePage.presaleTransfer')}
						</p>
						<div className="set_lng">
							<LngSet />
						</div>
					</div>
				</div>
				<Dropdown>
				{/* 类型 */}
					<Dropdown.Item key='region' title={t('MapHousePage.typeTitle')} 
						closeOnContentClick 
						forceRender 
						destroyOnClose
						className={typeSelect === true?'select':null}
					>
						<div onClick={(e)=>e.stopPropagation()}>
							<div className="title">{t('MapHousePage.typeSelect')}</div>
							<Selector
								value={selectedPresaleTypes}
								options={presaleTypes}
								multiple={true}
								onChange={(arr, context) => {
									var temp = [];
									for (let i = 0; i < context.items.length; ++i) {
										// if (context.items[i].value === 'ALL') {
										// 	temp = null;
										// 	break;
										// } else {
										// 	temp.push(context.items[i].value);
										// }
                                        temp.push(context.items[i].value);
									}
									setSelectedPresaleTypes(temp);
								}}
							/>
						</div>
						<div className="btn_ok" onClick={()=>{mapQuery(1)}}>{t('MapHousePage.confirmButtonText')}</div>
					</Dropdown.Item>
					
					{/* 状态 */}
					<Dropdown.Item key='stu' title={t('VIP.status')}
						closeOnContentClick 
						forceRender 
						destroyOnClose
						className={statusSelect === true?'select':null}
					>
						<div onClick={(e)=>e.stopPropagation()}>
							<div className="title">{t('VIP.statusSelect')}</div>
							<Selector
								// value={selectedPresaleTypes}
								// options={presaleTypes}
								// multiple={true}
								// onChange={(arr, context) => {
								// 	var temp = [];
								// 	for (let i = 0; i < context.items.length; ++i) {
								// 		// if (context.items[i].value === 'ALL') {
								// 		// 	temp = null;
								// 		// 	break;
								// 		// } else {
								// 		// 	temp.push(context.items[i].value);
								// 		// }
					   //                  temp.push(context.items[i].value);
								// 	}
								// 	setSelectedPresaleTypes(temp);
								// }}
								options={statusList}
								multiple={true}
								onChange={(arr, context) => {
								    var selectedItems = [];
								    for (let i = 0; i < context.items.length; ++i) {
								        if (context.items[i].value === 'ALL') {
								            selectedItems = null;
								            break;
								        } else {
								            selectedItems.push(context.items[i].value);
								        }
								    }
								    setStatus(selectedItems);
								}}
							/>
						</div>
						<div className="btn_ok" onClick={()=>{mapQuery(2)}}>{t('MapHousePage.confirmButtonText')}</div>
					</Dropdown.Item>
					
					{/* 户型 */}
					<Dropdown.Item key='status' title={t('MapHousePage.houseTypeTitle')} 
						closeOnContentClick 
						forceRender 
						destroyOnClose
						className={houseSelect === true?'select':null}
					>
						<div onClick={(e)=>e.stopPropagation()}>
							<div className="title">{t('MapHousePage.houseTypeSelect')}</div>
							<Selector
								value={selectedBedroom}
								options={houseTypes}
								multiple={true}
								onClick={(e)=>e.stopPropagation()}
								onChange={(arr, context) => {
									var temp = [];
									for (let i = 0; i < context.items.length; ++i) {
										// if (context.items[i].value === 'ALL') {
										// 	temp = ['ALL'];
										// 	break;
										// } else {
										// 	temp.push(context.items[i].value);
										// }
                                        temp.push(context.items[i].value);
									}
									setSelectedBedroom(temp);
								}}
							/>
						</div>
						<div className="btn_ok" onClick={()=>mapQuery(3)}>{t('MapHousePage.confirmButtonText')}</div>
					</Dropdown.Item>
					
					{/* 起价 */}
					<Dropdown.Item key='price' title={t('MapHousePage.priceTitle')} 
						closeOnContentClick 
						forceRender 
						destroyOnClose
						className={priceSelect === true?'select':null}
					>
						<div onClick={(e)=>e.stopPropagation()}>
							<div className="title">{t('MapHousePage.priceSelect')}</div>
							<Selector
								options={priceList}
								onChange={(arr, context) => {
									setAllPrice(false);
                                    setSelectedPrices(preSelectedPrices);
									for (let i = 0; i < context.items.length; ++i) {
                                        if (context.items[i].value === 'ALL') {
                                            console.log('ALL---')
											setAllPrice(true);
											setSelectedPrices(null);
											break;
                                        }
                                    }
								}}
							/>
							<div className="price_slide">
								<Slider
									range
                                    marks={marks}
									style={{ '--fill-color': '#D6AC58' }}
									onChange={value => {
										if (allPrice) {
											setSelectedPrices(null);
										} else {
                                            setSelectedPrices(value);
                                            setPreSelectedPrices(value);
										}
									}}
								/>
							</div>
							
						</div>
						<div className="btn_ok" onClick={()=>mapQuery(4)}>{t('MapHousePage.confirmButtonText')}</div>
					</Dropdown.Item>
				</Dropdown>
				<div className="map_box">
					{/* 地图 */}
					<ErrorBoundary>
						<Map {...props} getData={getListDataFromMap} list={mapShowList} detailTo={refDetailTo.current} />
					</ErrorBoundary>
					<img 
						className="close" 
						src={close} 
						style={{display:`${listClose === true?'block':'none'}`}}
						onClick={
							()=>{
								setListClose(false)
							}
						}
					 />
					<div className="house_list" style={{display:`${listClose === true?'block':'none'}`}}>
						<div className="house_ul">
							{
								mapShowList && mapShowList?.length > 0 && swiperData.map((item, index) => {
									return (
										<NavLink to={{pathname: `${refDetailTo.current}/${item.id}`,state : { ishistory: true }} } key={item.id}>
											<div className="house_li">
												<div className="house_img">
													{/* <img src={`${ImageURL}${item.coverPic}`} ali="pic" /> */}
													<Image className="house_image" src={item.coverPic} width={`100%`} height={`100%`} fit='cover' />
												</div>
												<div className="house_text">
													<div className="text_top">
														<p className="top_title">{item.name}</p>
														<p className="top_time">{item.builtYear}</p>
														<p className="top_addr">{item.city}</p>
													</div>
													<div className="text_bottom">
														<div className="text_price">
															{
																item.price === '0' || item.price === '0.00' || item.price === '0.0'?
																<p className="price">N/A</p>
																:
																<p className="price">${item.price}</p>
															}
															{
																item.price === '0' || item.price === '0.00' || item.price === '0.0'?
																null
																:<p hidden={window.localStorage.getItem('i18nextLng') === 'en' || window.localStorage.getItem('i18nextLng') === 'EN'}>
																	{t('MapHousePage.priceLabel')}
																</p>
															}
															
														</div>
														<img src={icon} alt="icon" />
													</div>
												</div>
											</div>
										</NavLink>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
			<TabBar />
		</Box>
    );
}
export default withRouter(OverlayVisible)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
        return <div style={{
            fontSize:'16px',
            textAlign:'center',
            width:'100%',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
	    }}><p></p></div>;
    }
    return this.props.children;
  }
}