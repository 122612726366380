import React,{useState} from 'react'
import { Collapse  } from 'antd-mobile';
import styled from 'styled-components'
import { useTranslation } from "react-i18next";

const Box = styled.div`
	.faq{
		padding:0.2rem;
		background:#fff;
		.adm-collapse{
			.adm-list.adm-list-default{
				margin-top:0;
				.adm-list-inner{
					.adm-list-item.adm-plain-anchor.adm-collapse-panel-header{
						border-bottom:0.01rem solid rgba(156,156,156,0.4);
						.adm-list-item-content{
							.adm-list-item-content-main{
								font-size:0.18rem;
								p{
									width:100%;
									
								}
							}
							.adm-list-item-content-arrow{
							}
						}
					}
					.adm-list-item.adm-plain-anchor.adm-collapse-panel-header.cur{
						border-bottom:transparent;
						.adm-list-item-content{
							color: #D6AC58;
							font-weight: bold;
							.adm-list-item-content-arrow{
								color: #D6AC58;
							}
						}
					}
					.adm-collapse-panel-content{
						background:rgba(214,172,88,0.2);
						color:#90752D;
						.adm-collapse-panel-content-inner{
							border-top:0.02rem solid #D6AC58;
							.adm-list-item{
								.adm-list-item-content{
									.adm-list-item-content-main{
										p{
											font-size:0.14rem;
											line-height:0.22rem;
											margin:0.1rem 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.foot{
		width:100%;
		padding:0 0.2rem;
		font-size:0.14rem;
		text-align:center;
		line-height:0.22rem;
		margin-top:0.2rem;
	}
`
const hide = {
	display:'none'
}
function FAQ() {
	const { t } = useTranslation();
	const [act1, setAct1] = useState(false);
	const [act2, setAct2] = useState(false);
	const [act3, setAct3] = useState(false);
	const [act4, setAct4] = useState(false);
	const [act5, setAct5] = useState(false);
	const [act6, setAct6] = useState(false);
	const [act7, setAct7] = useState(false);
	const [act8, setAct8] = useState(false);
	return (
		<Box>
			<div className="faq">
				<Collapse onChange={(e)=>{console.log()}}>
					<Collapse.Panel className={act1 === true?'cur':null} onClick={
						(e)=>{
							if(act1){
								setAct1(false)
							}else{
								setAct1(true)
							}
						}
					} key='1' title={t('FAQ.one.title')}>
						<p>{t('FAQ.one.info1')}</p>
						<p>{t('FAQ.one.info2')}</p>
					</Collapse.Panel>
					<Collapse.Panel className={act2 === true?'cur':null} onClick={
						(e)=>{
							if(act2){
								setAct2(false)
							}else{
								setAct2(true)
							}
						}
					} key='2' title={t('FAQ.two.title')}>
						<p>{t('FAQ.two.info1')}</p>
						<p>{t('FAQ.two.info2')}</p>
						<p>{t('FAQ.two.info3')}</p>
						<p>{t('FAQ.two.info4')}</p>
						<p>{t('FAQ.two.info5')}</p>
						<p>{t('FAQ.two.info6')}</p>
						<p>{t('FAQ.two.info7')}</p>
						<p>{t('FAQ.two.info8')}</p>
					</Collapse.Panel>
					<Collapse.Panel className={act3 === true?'cur':null} onClick={
						(e)=>{
							if(act3){
								setAct3(false)
							}else{
								setAct3(true)
							}
						}
					} key='3' title={t('FAQ.three.title')}>
						<p>{t('FAQ.three.info1')}</p>
						<p>{t('FAQ.three.info2')}</p>
						<p>{t('FAQ.three.info3')}</p>
					</Collapse.Panel>
					<Collapse.Panel className={act4 === true?'cur':null} onClick={
						(e)=>{
							if(act4){
								setAct4(false)
							}else{
								setAct4(true)
							}
						}
					} key='4' title={t('FAQ.four.title')}>
						<p>{t('FAQ.four.info1')}</p>
						<p>{t('FAQ.four.info2')}</p>
						<p>{t('FAQ.four.info3')}</p>
						<p>{t('FAQ.four.info4')}</p>
						<p>{t('FAQ.four.info5')}</p>
						<p>{t('FAQ.four.info6')}</p>
						<p>{t('FAQ.four.info7')}</p>
					</Collapse.Panel>
					<Collapse.Panel className={act5 === true?'cur':null} onClick={
						(e)=>{
							if(act5){
								setAct5(false)
							}else{
								setAct5(true)
							}
						}
					} key='5' title={t('FAQ.five.title')}>
						<p>{t('FAQ.five.info1')}</p>
						<p>{t('FAQ.five.info2')}</p>
						<p>{t('FAQ.five.info3')}</p>
						<p>{t('FAQ.five.info4')}</p>
						<p>{t('FAQ.five.info5')}</p>
						<p>{t('FAQ.five.info6')}</p>
					</Collapse.Panel>
					<Collapse.Panel className={act6 === true?'cur':null} style={t('FAQ.six.title') === ''?hide:''} onClick={
						(e)=>{
							if(act6){
								setAct6(false)
							}else{
								setAct6(true)
							}
						}
					} key='6' title={t('FAQ.six.title')}>
						<p>{t('FAQ.six.info1')}</p>
						<p>{t('FAQ.six.info2')}</p>
						<p>{t('FAQ.six.info3')}</p>
						<p>{t('FAQ.six.info4')}</p>
						<p>{t('FAQ.six.info5')}</p>
						<p>{t('FAQ.six.info6')}</p>
						<p>{t('FAQ.six.info7')}</p>
					</Collapse.Panel>
					<Collapse.Panel className={act7 === true?'cur':null} style={t('FAQ.seven.title') === ''?hide:''} onClick={
						(e)=>{
							if(act7){
								setAct7(false)
							}else{
								setAct7(true)
							}
						}
					} key='7' title={t('FAQ.seven.title')}>
						<p>{t('FAQ.seven.info1')}</p>
					</Collapse.Panel>
					<Collapse.Panel className={act8 === true?'cur':null} style={t('FAQ.eight.title') === ''?hide:''} onClick={
						(e)=>{
							if(act8){
								setAct8(false)
							}else{
								setAct8(true)
							}
						}
					} key='8' title={t('FAQ.eight.title')}>
						<p>{t('FAQ.eight.info1')}</p>
						<p>{t('FAQ.eight.info2')}</p>
					</Collapse.Panel>
				</Collapse>
			</div>
			<div className="foot">
				<p>Designed & Developed by Activator Tube Inc.</p>
			</div>
		</Box>
	)
}
export default FAQ