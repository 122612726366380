import React,{useState,useEffect} from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'
import {post} from '../../../service'
import { ImageURL } from "../../../service/config";
import styled from 'styled-components'
import { Image } from 'antd-mobile'
import { useTranslation } from 'react-i18next';


const Box = styled.div`
	background:#fff;
	display:flex;
	flex-direction: column;
	padding:0 0.2rem;
	.list_li{
		width:100%;
		height:1.34rem;
		border-bottom:0.01rem solid #e5e5e5;
		display:flex;
		align-items:center;
		justify-content: space-between;
		.list_img{
			width:1.4rem;
			height:0.98rem;
			margin-right:0.2rem;
			border-radius:0.05rem;
			overflow:hidden;
			display:flex;
			align-items:center;
			justify-content:center;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
		.list_content{
			width:calc(100% - 1.6rem);
			height:0.98rem;
			display:flex;
			flex-direction: column;
			justify-content: space-between;
			.list_p1{
				width:100%;
				display:flex;
				align-items:center;
				justify-content: space-between;
				color:#000;
				.list_p1-1{
					font-size:0.2rem;
					font-weight: 550;
				}
				.list_p1-2{
					font-size:0.16rem;
					color:#666666;
				}
				.list_p1-3{
					font-size:0.16rem;
					width:100%;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow:hidden;
					flex:1;
					span{
						margin-right:0.1rem;
						&:nth-last-child(1){
							margin-right:0
						}
					}
				}
			}
			.list_p2{
				width:80%;
				color:#90752D;
				font-size:0.16rem;
				overflow:hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.list_p3{
				font-size:0.12rem;
				color:#666666;
			}
		}
		&:nth-last-child(1){
			border:none;
		}
	}
	
`
const hide = {
	display:'none'
}
function HouseList(props){
	const [list, setLst] = useState([])
	let {t, i18n} = useTranslation()
	const lng = window.localStorage.getItem('i18nextLng')
	useEffect(() => {
		post(`/wap/house/queryHomeHotHouse`,{
			language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`,
		}).then( res => {
				setLst(res.data)
		})
	}, []);
	return (
		<Box>
			{
				list.map((item, index) => (
					<NavLink to={{pathname: `/details_transfer/${item.id}`,state : { ishistory: true }} } className="list_li" key={index}>
						<div className="list_img">
							<Image src={item.coverPic} width={`100%`} height={`100%`} fit='cover' />
						</div>
						<div className="list_content">
							<div className="list_p1">
							{
								item.price === '0' || item.price === '0.0' || item.price === '0.00' ?
								<p className="list_p1-1">N/A</p>
								:
								<p className="list_p1-1">${item.price}{t('list.unit')}</p>
							}
							{
								item.price === '0' || item.price === '0.0' || item.price === '0.00' ?
								<p className="list_p1-2">N/A/{t('list.unit2')}</p>
								:
								<p className="list_p1-2">${item.unitPrice}/{t('list.unit2')}</p>
							}
								
							</div>
							<div className="list_p1" style={lng === 'en'?hide:null}>
								<p className="list_p1-3">
                                    <span>{item.houseType}</span>
                                    <span>{item.type}</span>
									<span>{item.city}</span>
								</p>
                                <p className="list_p1-2">{item.area}{t('list.unit2')}</p>
							</div>
							<div className="list_p1" style={lng === 'en'?null:hide}>
								<p className="list_p1-3">
									{item.houseType}
								</p>
								<p className="list_p1-2">{item.area}{t('list.unit2')}</p>
							</div>
                            <div className="list_p1" style={lng === 'en' ? null : hide}>
                                <p className="list_p1-3">
                                    {item.type}
                                </p>
								<p className="list_p1-3">
									{item.city}
								</p>
							</div>
							<p className="list_p2">{item.communityName}</p>
							<p className="list_p3">PresaleVIP Realty</p>
						</div>
					</NavLink>
				))
			}
		</Box>
	)
}
export default withRouter(HouseList)