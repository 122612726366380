import React,{useState,useEffect} from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'
import {post} from '../../../service'
import { ImageURL } from "../../../service/config";
import { useTranslation } from 'react-i18next';
import { Image } from 'antd-mobile'
import styled from 'styled-components'

import icon from "../../../assets/img/icon-02.png";
const Box = styled.div`
	width:100%;
	display:flex;
	.houser_ul{
		display:flex;
		width:100%;
		overflow-x:scroll;
		.houser_li{
			// width:1.6rem;
			width:38%;
			display:flex;
			flex-direction: column;
			margin-right:0.2rem;
			flex-shrink: 0;
			.li_img{
				width:100%;
				height:1rem;
				overflow:hidden;
				position: relative;
				display:flex;
				align-items:center;
				justify-content:center;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				border-radius:0.05rem;
				.li_link{
					width:0.94rem;
					height:0.36rem;
					display:flex;
					align-items:center;
					justify-content:center;
					border-radius:0 0.09rem 0 0;
					position: absolute;
					left:0;
					bottom:0;
					color:#fff;
					font-size:0.14rem;
					background:rgba(255,255,255,0.3);
					backdrop-filter: blur(0.02rem) brightness(50%);
					p{
						// width:50%;
						// text-overflow: inherit;
						// overflow: hidden;
						// white-space: nowrap;
					}
					img{
						height:0.11rem;
						margin-left:0.06rem;
					}
				}
			}
			.li_text{
				width:100%;
				display:flex;
				flex-direction: column;
				.li_text1{
					width:100%;
					display:flex;
					align-items:center;
					justify-content: space-between;
					margin-top:0.1rem;
					.li_name{
						width:100%;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						font-size:0.14rem;
						color:#000;
						font-weight: 550;
						flex:1;
						margin-right:0.05rem;
					}
					.li_addr{
						padding:0.02rem 0.06rem;
						background:#D6AC58;
						border-radius:0.03rem;
						font-size:0.12rem;
						color:#fff;
					}
					.li_price{
						display:flex;
						align-items:center;
						font-weight: 550;
						.li_price1{
							font-size:0.18rem;
							color:#000;
							margin-right:0.06rem;
						}
						.li_price2{
							font-size:0.12rem;
							color:#000;
						}
					}
					.li_time{
						font-size:0.12rem;
						color:#666666;
					}
				}
				.li_text1.money{
					flex-wrap:wrap;
				}
			}
		}
	}
	@media (min-width: 750px){
		.houser_ul{
			&::-webkit-scrollbar{
					width:14px;
					height:14px;
			}
			&::-webkit-scrollbar-button{
					display:none;
			}
			&::-webkit-scrollbar-track{
					background:rgb(241,241,241);
			}
			&::-webkit-scrollbar-track-piece{
					background:rgb(241,241,241);
			}
			&::-webkit-scrollbar-thumb{
				background:rgb(193,193,193);
			}
			&::-webkit-scrollbar-corner {
				display:none;
			}
			&::-webkit-scrollbar-resizer  {
				bdisplay:none;
			}
			.houser_li{
				.li_img{
					height:2rem;
				}
			}
		}
	}
`
const hide = {
    display: 'none'
}
function HouseList(props){
	const [list, setLst] = useState([])
	const lng = window.localStorage.getItem('i18nextLng')
	let {t} = useTranslation()
	useEffect(() => {
		post(`/wap/building/queryHomeHotBuildings`,{
			language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`,
		}).then( res => {
				setLst(res.data)
		})
	}, []);
	return (
		<Box>
			<div className="houser_ul">
			{
				list.map((item, index) => (
				  <NavLink to={{pathname: `/details_vip/${item.id}`,state : { ishistory: true }} } className="houser_li" key={index}>
				  	<div className="li_img">
							<Image src={item.coverPic} width={`100%`} height={`100%`} fit='cover' />
				  		<div className="li_link">
				  			<p>{t('home.ShowroomPreview')}</p>
				  			<img src={icon} alt="icon" />
				  		</div>
				  	</div>
				  	<div className="li_text">
				  		<div className="li_text1">
				  			<p className="li_name">{item.name}</p>
				  			<p className="li_addr">{item.city}</p>
				  		</div>
				  		<div className="li_text1 money">
				  			<div className="li_price">
							{
								item.minimumPrice === '0' || item.minimumPrice === '0.0' || item.minimumPrice === '0.00'?
								<p className="li_price1">N/A</p>
								:
								<p className="li_price1">${item.minimumPrice}</p>
							}
							{
								item.minimumPrice === '0' || item.minimumPrice === '0.0' || item.minimumPrice === '0.00'?
								null
								:
								<p className="li_price2">{lng === 'zh-CN'?'万起':lng === 'zh-TW'?'萬起':null}</p>
							}
				  				
				  				
				  			</div>
				  			<p className="li_time">{item.builtYear}{lng === 'zh-CN'?'交房':lng === 'zh-TW'?'交房':null}</p>
				  		</div>
				  	</div>
				  </NavLink>
				))
			}
			</div>
		</Box>
	)
}
export default withRouter(HouseList)