import React from 'react'
import { Redirect } from "react-router-dom";
import Home from '../pages/Home/index.js';
import Vip from '../pages/VIP';
import transfer from '../pages/King_transfer';
import contact_us from '../pages/Contact_us';
import map_house from '../pages/Map_house';
import set_lng from '../pages/Select_language';
import details_vip from '../pages/Details_vip';
import details_transfer from '../pages/Details_transfer';
import details_video from '../pages/Details_video';
import form from '../pages/Form';

const routes = [
	{
	  path: "/",
		exact: true,
		component: Home,
		name: ''
	},
	{
		path: "/home",
		component: Home,
		name:'home'
	},
	{
		path: "/vip",
		component: Vip,
		name:'vip'
	},
	{
		path: "/king_transfer",
		component: transfer,
		name:'king_transfer'
	},
	{
		path: "/contact_us",
		component: contact_us,
		name:'contact_us'
	},
	{
		path: "/map_house",
		component: map_house,
		name:'map_house'
	},
	{
		path: "/set_language",
		component: set_lng,
		name:'set_language'
	},
	{
		path: "/details_vip/:id",
		component: details_vip,
		name:'details_vip'
	},
	{
		path: "/details_transfer/:id",
		component: details_transfer,
		name:'details_transfer'
	},
	{
		path: "/details_video",
		component: details_video,
		name:'details_video'
	},
	{
		path: "/form",
		component: form,
		name:'form'
	}
	
];

export default routes;