import React from 'react'
import { withRouter } from "react-router";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

import back from "../../assets/img/icon-back.png";
import check from "../../assets/img/icon-check.png";
import cn from "../../assets/img/lng-cn.png";
import en from "../../assets/img/lng-en.png";
import tw from "../../assets/img/lng-tw.png";
import cn2 from "../../assets/img/lng-cn-2.png";
import en2 from "../../assets/img/lng-en-2.png";
import tw2 from "../../assets/img/lng-tw-2.png";
const Box = styled.div`
	width:100%;
	.top{
		width:100%;
		padding:0 0.2rem;
		display:flex;
		align-items:center;
		margin-top:0.1rem;
		font-size:0.24rem;
		font-weight: 550;
		.icon_back{
			height:0.36rem;
			margin-right:0.12rem;
		}
	}
	.list{
		width:100%;
		display:flex;
		flex-direction: column;
		align-items:center;
		padding: 0 0.2rem;
		background:#fff;
		margin-top:0.2rem;
		.list_li{
			width:100%;
			display:flex;
			align-items:center;
			justify-content: space-between;
			color:#000000;
			font-size:0.18rem;
			border-bottom:0.01rem solid #E8E5DD;
			padding: 0.17rem 0;
			cursor: pointer;
			.li_left{
				display:flex;
				align-items:center;
				.li_icon{
					height:0.35rem;
					margin-right:0.12rem;
				}
			}
			.li_right{
				height:0.12rem;
				display:none;
			}
			&:nth-last-child(1){
				border:none;
			}
		}
		.list_li.cur{
			font-weight: 550;
			color:#D6AC58;
			.li_right{
				display:block;
			}
		}
	}
	@media (min-width: 750px){
		.max-box{
			max-width:7.2rem;
			margin:0 auto 0 auto;
		}
	}
`;
function Language(props){
	const lng = window.localStorage.getItem('i18nextLng')
	let {t, i18n} = useTranslation()
	let title = document.getElementById('nav_title')
	let elng = window.localStorage.getItem('i18nextLng')
	if(elng === 'zh-CN'){
		title.innerText=`PresaleVIP.com 楼花王`
	}else{
		title.innerText=`PresaleVIP.com 樓花王`
	}
	return (
		<Box>
			<div className="max-box">
				<div className="top">
					<img src={back} className="icon_back" alt='back'
						onClick={
							()=>{
								if(props.location.state?.ishistory){
									props.history.goBack(-1)
								}else{
									props.history.push(`/`)
								}
							}
						}
					 />
					<p>{t('Select_language')}</p>
				</div>
				<div className="list">
					<div className={'list_li ' + `${lng !== 'zh-CN' && lng !== 'zh-TW'?'cur':null}`} 
						onClick={
							(e)=>{
								i18n.changeLanguage('en');
								if(props.location.state?.ishistory){
									props.history.goBack(-1)
								}else{
									props.history.push(`/`)
								}
							}
						}
					>
						<div className="li_left">
							<img src={lng !== 'zh-CN' && lng !== 'zh-TW'?en:en2} className="li_icon" alt="lng" />
							<p>English</p>
						</div>
						<img src={check} className="li_right" alt="icon" />
					</div>
					
					<div className={'list_li ' + `${lng=== 'zh-CN'?'cur':null}`}
						onClick={
							(e)=>{
								i18n.changeLanguage('zh-CN');
								if(props.location.state?.ishistory){
									props.history.goBack(-1)
								}else{
									props.history.push(`/`)
								}
							}
						}
					>
						<div className="li_left">
							<img src={lng === 'zh-CN'?cn:cn2} className="li_icon" alt="lng" />
							<p>中文简体</p>
						</div>
						<img src={check} className="li_right" alt="icon" />
					</div>
					
					<div className={'list_li ' + `${lng=== 'zh-TW'?'cur':null}`}
						onClick={
							(e)=>{
								i18n.changeLanguage('zh-TW');
								if(props.location.state?.ishistory){
									props.history.goBack(-1)
								}else{
									props.history.push(`/`)
								}
							}
						}
					>
						<div className="li_left">
							<img src={lng === 'zh-TW'?tw:tw2} className="li_icon" alt="lng" />
							<p>中文繁體</p>
						</div>
						<img src={check} className="li_right" alt="icon" />
					</div>
				</div>
			</div>
		</Box>
	)
}
export default withRouter(Language)