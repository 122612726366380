import React,{useState,useEffect,useRef} from 'react'
import { Swiper } from 'antd-mobile'
import { post,get} from '../../../service'
import { ImageURL } from "../../../service/config";
import { Image } from 'antd-mobile'

import styled from 'styled-components'
import bg from "../../../assets/img/bg-01.png";
import icon1 from "../../../assets/img/icon-13.png";
import icon2 from "../../../assets/img/icon-14.png";
import iconjt from "../../../assets/img/icon-Arrows.svg";
const Box = styled.div`
	height:2.3rem;
	position: relative;
	.right{
		position: absolute;
		right: -50px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		display:none;
	}
	.left{
		position: absolute;
		left: -50px;
		top: 50%;
		transform: translateY(-50%) rotate(180deg);;
		cursor: pointer;
		display:none;
	}
	.adm-swiper{
		height:100%;
		.banner_img{
			width:100%;
			height:2.3rem;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
		.adm-swiper-indicator{
			transform: translateX(-50%);
			.adm-page-indicator{
				.adm-page-indicator-dot{
					width:0.16rem;
					height:0.16rem;
					background: url(${icon1}) no-repeat;
					background-size:100% 100% !important;
				}
				.adm-page-indicator-dot-active{
					background: url(${icon2}) no-repeat;
				}
			}
		}
	}
	@media (min-width: 750px){
		height:3rem;
		.left,.right{
			display:block;
		}
		.adm-swiper {
			.banner_img{
				height:3rem;
			}
		}
	}
`;
function BannerSwiper (props){
	const lng = window.localStorage.getItem('i18nextLng')
	const [list, setLst] = useState([])
	const ref = useRef()
	useEffect(() => {
		post(`/wap/banner/list`,{
			language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`,
			type:'AD'
		}).then( res => {
			setLst(res.data.data)
			// 微信分享
			get(`/wechar/share/`,{
				url:window.location.href
			}).then( res => {
				window.wx.config({
				  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				  appId:  res.data.appId, // 必填，公众号的唯一标识
				  timestamp: res.data.timestamp, // 必填，生成签名的时间戳
				  nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
				  signature: res.data.signature,// 必填，签名，见附录1
				  jsApiList: [
				    'updateAppMessageShareData',
				    'updateTimelineShareData',
				  ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
				})
			})
			// 判断是否微信浏览器
			const ua = window.navigator.userAgent.toLowerCase()
			if (ua.indexOf('micromessenger') < 0) return false
			// 开局调用分享
			window.wx.ready(() => {
				//分享给朋友
				window.wx.updateAppMessageShareData({
					title: '楼花王 | 大温地区最全楼花信息平台', // 分享标题
					desc: '第一选房 · 第一价格 · 第一优惠 · 第一锁定', // 分享描述
					link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
					imgUrl: res.data.data[0].pics, // 分享图标
					success: function () {
					  // 用户确认分享后执行的回调函数
					},
					cancel: function () {
					  // 用户取消分享后执行的回调函数
					}
				})
				
				  //分享到朋友圈
				window.wx.updateTimelineShareData({
					title: '楼花王 | 大温地区最全楼花信息平台', // 分享标题
					link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
					imgUrl: res.data.data[0].pics, // 分享图标
					success: function () {
					  // 用户确认分享后执行的回调函数
					},
					cancel: function () {
					  // 用户取消分享后执行的回调函数
					}
				})
			})
		})
	}, []);
	
	const items = list.map((item, index) => (
	  <Swiper.Item key={index}>
			<div className="banner_img"
				onClick={
					()=>{
						if(item.link !== '' && item.link !== null){
							window.location.href=item.link
						}
					}
				}
			>
				<Image src={item.pics} width={`100%`} height={`100%`} fit='cover' />
			</div>
	  </Swiper.Item>
	))
  return (
    <Box>
		<div className="left"
			onClick={() => {
				ref.current?.swipePrev()
			}}
		><img src={iconjt} /></div>
		<Swiper 
			loop={true} 
			autoplay 
			className="swiper_page"
			autoplayInterval={5000}
			ref={ref}
		>{items}</Swiper>
		
		<div className="right"
			onClick={() => {
				ref.current?.swipeNext()
			}}
		><img src={iconjt} /></div>
    </Box>
  )
}
export default BannerSwiper