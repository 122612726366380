import React, { useState,useEffect } from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'
import {post} from '../../service'
import { ImageURL } from "../../service/config";
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { Loading,Image,InfiniteScroll } from 'antd-mobile';
import icon from "../../assets/img/icon-02.png";

const Box = styled.div`
	width:100%;
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.list_li{
		width:100%;
		display:flex;
		flex-direction: column;
		margin-bottom:0.2rem;
		.li_img{
			width:100%;
			height:2.26rem;
			border-radius:0.05rem;
			overflow:hidden;
			display:flex;
			align-items:center;
			justify-content:center;
			position: relative;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			border-radius:0.05rem;
			.li_link{
				position: absolute;
				bottom:0;
				left:0;
				display:flex;
				align-items:center;
				justify-content:flex-start;
				.li_link1{
					width:0.8rem;
					height:0.3rem;
					line-height:0.3rem;
					text-align:center;
					font-size:0.12rem;
					color:#fff;
					background:#58902D;
					border-radius:0.02rem;
				}
                .li_link1.Register {
                    background: #58902D;
                }
                .li_link1.OnSale {
                    background: #D6AC58;
                }
                .li_link1.Ending {
                    background: #2D5590;
                }
                .li_link1.Transfer {
                    background: #C22A18;
                }
                .li_link1.SoldOut {
                    background: #9C9C9C;
                }
				.li_link2{
					width:1rem;
					height:0.3rem;
					display:flex;
					align-items:center;
					justify-content:center;
					border-radius:0 0.09rem 0 0;
					color:#fff;
					font-size:0.14rem;
					background:rgba(255,255,255,0.3);
					backdrop-filter: blur(0.02rem) brightness(50%);
					img{
						height:0.11rem;
						margin-left:0.06rem;
					}
				}
			}
			
		}
		.li_p1{
			width:100%;
			overflow:hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: 550;
			font-size:0.2rem;
			color:#000;
			margin:0.04rem 0;
		}
		.li_p2{
			width:100%;
			display:flex;
			align-items:center;
			font-size:0.14rem;
			color:#666;
			justify-content: flex-start;
			p{
				width:100%;
				margin-right:0.05rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		.li_p3{
			width:100%;
			display:flex;
			align-items:center;
			font-size:0.14rem;
			color:#666;
			margin-top:0.04rem;
			p{
				width:50%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&:first-child{
					margin-right:0.12rem;
				}
				span{
					margin:0 0.05rem;
				}
			}
		}
		.li_p4{
			width:100%;
			display:flex;
			align-items:center;
			font-weight: 550;
			color:#9C9C9C;
			font-size:0.12rem;
			margin-top:0.08rem;
			.li_p4_1{
				font-size:0.18rem;
				color:#D6AC58;
				margin-right:0.06rem;
			}
		}
	}
	@media (min-width: 750px){
		.list_li{
			.li_img{
				height:3.5rem;
			}
		}
	}
	@media (min-width:570px) and (max-width: 749px) {
		.list_li{
			.li_img{
				height:3rem;
			}
		}
	}
	@media (min-width:440px) and (max-width: 569px) {
		.list_li{
			.li_img{
				height:2.5rem;
			}
		}
	}
`

const hide = {
	display:'none'
}
function List(props) {
	const lng = window.localStorage.getItem('i18nextLng')
	let {t} = useTranslation()
	const [list, setLst] = useState([]);
	// const [queryParam, setQueryParam] = useState([props.queryParam]);
	const [pagination, setPagination] = useState({ page: 1, size: 10 });
	const [hasMore, setHasMore] = useState(true);
	const InfiniteScrollContent = (props) => {
		return (
			<>
				{
					props.hasMore ? (
						<>
							<br />
							<span style={{textAlign: 'center'}}>{t('HouseListCloum.InfiniteScrollContent.loading')}</span>
							<Loading />
						</>
					) : (
						<>
							<br />
							<span style={{ textAlign: 'center' }}>{t('HouseListCloum.InfiniteScrollContent.noMoreData')}</span>
						</>
					)
				}
			</>
		)
	}
    useEffect(() => {
        // 由于切换条件后页面需要重新渲染
        // 在这个地方，也就是切换条件后，一定会执行到这里，将控制分页的变量全部重置
        setPagination({ page: 1, size: 10 });
        setHasMore(true);
        setLst([]);
        post(`/wap/building/listBuildings`, {
            language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`,
            ...props.queryParam
        }).then(res => {
            // 为了避免在标签中做过长的判断 将状态标识的颜色判断在这里特殊处理，新加一个属性statusClassName，根据res.data[i].status进行判断赋给不同的className
			for (let i = 0; i < res?.data?.data?.length; ++i) {
				// 由于存在不同语言的情况，因此要分别对简体中文，繁体中文，英文的status字段进行分别判断
				if (res.data.data[i].status === 'Sold Out' || res.data.data[i].status === '售罄' || res.data.data[i].status === '售罄') {
						res.data.data[i].statusClassName = 'li_link1 SoldOut';
				} else if (res.data.data[i].status === 'Transafer' || res.data.data[i].status === '转让' || res.data.data[i].status === '轉讓') {
						res.data.data[i].statusClassName = 'li_link1 Transfer';
				} else if (res.data.data[i].status === 'VIP On Sale' || res.data.data[i].status === '在售' || res.data.data[i].status === '在售') {
						res.data.data[i].statusClassName = 'li_link1 OnSale';
				} else if (res.data.data[i].status === 'Register' || res.data.data[i].status === '注册' || res.data.data[i].status === '註冊') {
						res.data.data[i].statusClassName = 'li_link1 Register';
				} else if (res.data.data[i].status === 'Ending' || res.data.data[i].status === '尾盘' || res.data.data[i].status === '尾盤') {
						res.data.data[i].statusClassName = 'li_link1 Ending';
				} else {
						res.data.data[i].statusClassName = 'li_link1';
				}
			}
			setLst(res.data.data)
		})
    }, [props.queryParam]);
    
    /**
     * 上拉加载
     */
    async function loadMore() {
        const res = await post(`/wap/building/listBuildings`, {
            language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`,
            ...props.queryParam,
            ...pagination
        });
        var tempList = list;
        for (let i = 0; i < res?.data?.data?.length; ++i) {
            if (res.data.data[i].status === 'Sold Out' || res.data.data[i].status === '售罄' || res.data.data[i].status === '售罄') {
                res.data.data[i].statusClassName = 'li_link1 SoldOut';
            } else if (res.data.data[i].status === 'Transafer' || res.data.data[i].status === '转让' || res.data.data[i].status === '轉讓') {
                res.data.data[i].statusClassName = 'li_link1 Transfer';
            } else if (res.data.data[i].status === 'On Sale' || res.data.data[i].status === '在售' || res.data.data[i].status === '在售') {
                res.data.data[i].statusClassName = 'li_link1 OnSale';
            } else if (res.data.data[i].status === 'Register' || res.data.data[i].status === '注册' || res.data.data[i].status === '註冊') {
                res.data.data[i].statusClassName = 'li_link1 Register';
            } else if (res.data.data[i].status === 'Ending' || res.data.data[i].status === '尾盘' || res.data.data[i].status === '尾盤') {
                res.data.data[i].statusClassName = 'li_link1 Ending';
            } else {
                res.data.data[i].statusClassName = 'li_link1';
            }
            tempList.push(res.data.data[i]);
        }
        setLst(tempList);
        // 设置当前分页参数
        setPagination({ page: pagination.page + 1, size: 10 })
        // 判断是否还可以继续分页
        if (pagination.page * pagination.size > res?.data?.count) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    } 

    return (
		<>
			<Box>
				{
					list.map((item, index) => (
						<NavLink to={{pathname: `/details_vip/${item.id}`,state : { ishistory: true }} } className="list_li" key={index}>
							<div className="li_img">
								<Image lazy src={item.coverPic} width={`100%`} height={`100%`} fit='cover' />
								<div className="li_link">
									<div className={item.statusClassName}>{item.status}</div>
									<div className="li_link2">
										<p>{t('HouseListCloum.NavLink.preview')}</p>
										<img src={icon} alt="icon" />
									</div>
								</div>
							</div>
							<p className="li_p1">{item.name}</p>
							<div>
								 <div className="li_p2">
									<p>
										{item.city}&nbsp;&nbsp;|&nbsp;&nbsp;
										{item.communityName}&nbsp;&nbsp;
										{t('HouseListCloum.NavLink.communityName')}&nbsp;&nbsp;|&nbsp;&nbsp;
										<span style={lng === 'en'?null:hide}>Est.</span>{ item.builtYear}{t('HouseListCloum.NavLink.builtYear')}
									</p>
								 </div>
								 <div className="li_p3">
									<p>{item.totalFloor} {t('HouseListCloum.NavLink.totalFloor')}<span>|</span>{ item.totalHouse } {t('HouseListCloum.NavLink.totalHouse')}</p>
								 </div>
							 </div>
							 <div className="li_p4">
							 {
								item.minimumPrice === '0' || item.minimumPrice === '0.0' || item.minimumPrice === '0.00'?
								<p className="li_p4_1">N/A</p>
								:
								<p className="li_p4_1">${item.minimumPrice}{t('HouseListCloum.NavLink.minimumPriceLabel')}</p>
							 }
							 </div>
						</NavLink>
					))
				}
            </Box>
            {/* 无限滚动 */}
			<InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
					<InfiniteScrollContent hasMore={hasMore} />
			</InfiniteScroll>
		</>
	)
}
export default withRouter(List)
