import React,{useState,useEffect} from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'
import { post} from '../../service'
import { ImageURL } from "../../service/config";
import { useTranslation } from "react-i18next";
import { InfiniteScroll,Loading,Image } from 'antd-mobile';
import styled from 'styled-components'

const Box = styled.div`
	.list{
		display:flex;
		flex-direction: column;
		background:#fff;
		padding:0 0.2rem;
		.list_li{
			width:100%;
			height:1.34rem;
			border-bottom:0.01rem solid #e5e5e5;
			display:flex;
			align-items:center;
			justify-content: space-between;
			.list_img{
				width:1.4rem;
				height:0.98rem;
				margin-right:0.2rem;
				border-radius:0.05rem;
				overflow:hidden;
				display:flex;
				align-items:center;
				justify-content:center;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
			}
			.list_content{
				width:calc(100% - 1.6rem);
				height:0.98rem;
				display:flex;
				flex-direction: column;
				justify-content: space-between;
				.list_p1{
					width:100%;
					display:flex;
					align-items:center;
					justify-content: space-between;
					color:#000;
					.list_p1-1{
						font-size:0.2rem;
						font-weight: 550;
						width: 55%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.list_p1-2{
						font-size:0.16rem;
						color:#666666;
						width: 45%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						text-align: right;
					}
					.list_p1-3{
						font-size:0.16rem;
						width:80%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						flex:1;
						span{
							margin-right:0.1rem;
							&:nth-last-child(1){
								margin-right:0
							}
						}
					}
					.house{
						color:#90752D;
					}
				}
                .list_p4 {
					width:100%;
					display:flex;
					align-items:center;
					justify-content: space-between;
					color:#000;
                    .list_p4-1 {
                        font-size:0.16rem;
                        width:100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow:hidden;
                        flex:1;
                        span{
                            margin-right:0.1rem;
                            &:nth-last-child(1){
                                margin-right:0
                            }
                        }
                    }
                }
				.list_p2{
					width:80%;
					color:#90752D;
					font-size:0.16rem;
					overflow:hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.list_p3{
					font-size:0.12rem;
					color:#666666;
				}
			}
			&:nth-last-child(1){
				border:none;
			}
		}
	}
`
const hide = {
    display: 'none'
}

function HouseList(props){
	const lng = window.localStorage.getItem('i18nextLng')
	const [list, setList] = useState([])
	const { t } = useTranslation();
	const [hasMore, setHasMore] = useState(true)
	const [pagination, setPagination] = useState({ page: 1, size: 10 });
	const InfiniteScrollContent = (props) => {
	    return (
	        <>
	            {
	                props.hasMore ? (
	                    <>
	                        <br />
	                        <span style={{textAlign: 'center'}}>{t('HouseListCloum.InfiniteScrollContent.loading')}</span>
	                        <Loading />
	                    </>
	                ) : (
	                    <>
	                        <br />
	                        <span style={{ textAlign: 'center' }}>{t('HouseListCloum.InfiniteScrollContent.noMoreData')}</span>
	                    </>
	                )
	            }
	        </>
	    )
	}
	useEffect(() => {
		setHasMore(true);
		setPagination({ page: 1, size: 10 });
		setList([]);
		post(`/wap/house/queryHouseListItem`,{
			language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`,
			page: props.arr.page, 
			size: 10, 
			houseType:props.arr.houseType,
			orderBy:props.arr.sort[0],
			prices:props.arr.price,
			cities:props.arr.region,
		}).then( res => {
				setList(res.data.data);
		})
	}, [props.arr])
	async function loadMore() {
	    await post(`/wap/house/queryHouseListItem`, {
				 language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`,
				 page: pagination.page, 
				 size: 10, 
				 houseType:props.arr.houseType,
				 orderBy:props.arr.sort[0],
				 prices:props.arr.price,
				 cities:props.arr.region,
	    }).then((res) => {
	        var tempList = list;
	        for (let i = 0; i < res?.data?.data?.length; ++i) {
	            tempList.push(res.data.data[i]);
	        }
	        setList(tempList);
	        setPagination({ page: pagination.page + 1, size: 10 })
	        if (pagination.page * pagination.size > res?.data?.count) {
	            setHasMore(false);
	        } else {
	            setHasMore(true);
	        }
	    })
	}
	return (
		<Box>
			<div className="list">
			{
				list?list.map((item, index) => (
					<NavLink to={{pathname: `/details_transfer/${item.id}`,state : { ishistory: true }} } className="list_li" key={index}>
						<div className="list_img">
						<Image lazy src={item.coverPic} width={`100%`} height={`100%`} fit='cover' />
						</div>
                        <div className="list_content">
                            <div className="list_p1">
								{
									item.price === '0' || item.price === '0.0' || item.price === '0.00'?
									<p className="list_p1-1">N/A</p>
									:
									<p className="list_p1-1">${item.price}{t('list.unit')}</p>
								}
                                
                                <p className="list_p1-2">{item.area}{t('list.unit2')}</p>
							</div>
							<div className="list_p1" style={window.localStorage.getItem('i18nextLng') === 'en' ? hide : null}>
								<p className="list_p1-3">
                                    <span>{item.houseType}</span>
                                    <span style={window.localStorage.getItem('i18nextLng') === 'en' ? hide : null}>{item.type}</span>
                                    <span style={window.localStorage.getItem('i18nextLng') === 'en' ? hide : null}>{item.city}</span>
								</p>
                            </div>
                            <div className="list_p1" style={window.localStorage.getItem('i18nextLng') === 'en' ? null : hide}>
                                <p className="list_p1-3">
                                    <span>{item.houseType}</span>
                                </p>
                            </div>
                            <div className="list_p1">
                                <p className="list_p1-3 house">
                                    <span>{item.communityName}</span>
                                    <span style={window.localStorage.getItem('i18nextLng') !== 'en' ? hide : null}>{item.type}</span>
                                    <span style={window.localStorage.getItem('i18nextLng') !== 'en' ? hide : null}>{item.city}</span>
                                </p>
                            </div>
							{/* <p className="list_p2">{item.communityName}</p> */}
                            <div className="list_p4">
                                <p className="list_p4-1">
                                    <span>
                                        {window.localStorage.getItem('i18nextLng') === 'en' ? 'Est. ' : null}
                                        {item.builtYear}
                                        {window.localStorage.getItem('i18nextLng') !== 'en' ? '建成' : null}
                                    </span>
                                    <span>
                                        {
                                            item.floor <= 10 ? t('list.lowFloor') : item.floor >= 11 && item.floor <= 20 ? t('list.midFloor') : t('list.highFloor')
                                        }
                                    </span>
                                    <span>
                                        {item.parkingSpace !== null && item.parkingSpace !== 0 && item.parkingSpace ? t('list.parking') : null}
                                    </span>
                                </p>
                            </div>
						</div>
					</NavLink>
				)):null
			}
			</div>
			<InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
			    <InfiniteScrollContent hasMore={hasMore} />
			</InfiniteScroll>
		</Box>
	)
}
export default withRouter(HouseList)