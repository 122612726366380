import React,{useState,useEffect,useRef} from 'react'
import {Dialog,Picker,Toast} from 'antd-mobile'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { post,get } from '../../service';
import LngSet from '../../components/Lng_set'
import validator from 'validator';

import back from "../../assets/img/icon-back.png";
import icon from '../../assets/img/icon-03.png';
import icon_p from '../../assets/img/icon-16.png';

const Box = styled.div`
	.top{
		width:100%;
		padding:0 0.2rem;
		display:flex;
		align-items:center;
		margin-top:0.2rem;
		font-size:0.24rem;
		font-weight: 550;
		justify-content: space-between;
		.left{
			display:flex;
			align-items:center;
			.icon_back{
				height:0.36rem;
				margin-right:0.12rem;
			}
			p{
				line-height:0.36rem;
			}
		}
		
		.lng_select{
			>div{
				right:0.2rem;
			}
		}
	}
	.form_box{
		width:100%;
		display:flex;
		padding:0 0.2rem;
		flex-direction: column;
		align-items:center;
		margin-top:0.32rem;
		.form_inlin{
			width:100%;
			height:0.6rem;
			display:flex;
			margin-bottom:0.2rem;
			position: relative;
			border:0.01rem solid #bbb;
			border-radius:0.05rem;
			transition: all 0.2s ease-in-out;
			.placeholder{
				color: #BBBBBB;
				position: absolute;
				left:0.2rem;
				top:50%;
				font-size:0.18rem;
				transition: all 0.4s ease-in-out;
				transform: translateY(-50%);
				z-index:-1;
				display:flex;
				align-items:center;
				span{
					margin-top: 0.08rem;
					margin-right:0.03rem;
				}
				.color{
					opacty:0;
					transition: all 0.2s ease-in-out;
				}
			}
			.placeholder.phone{
				left:1.2rem;
			}
			.form_border{
				// border-color:#BBBBBB;
				// border-style:solid;
				border:none;
			}
			.form_left{
				width:0.38rem;
				border-left-width:0.01rem;
				border-top-width:0.01rem;
				border-bottom-width:0.01rem;
				border-radius:0.05rem 0 0 0.05rem;
			}
			.form_middle{
				width:auto;
				padding:0 0.1rem;
				border-bottom-width:0.01rem;
				position: relative;
				.form_title{
					margin-top:-0.08rem;
					font-size:0.16rem;
					color:#9c9c9c;
				}
			}
			.form_right{
				border-right-width:0.01rem;
				border-top-width:0.01rem;
				border-bottom-width:0.01rem;
				flex:1;
				border-radius:0 0.05rem 0.05rem 0;
			}
			.form_input{
				position: absolute;
				left:0;
				top:0;
				width:100%;
				height:100%;
				border:none;
				outline:none;
				padding:0 0.2rem;
				background:none;
				color:#D6AC58;
				font-size:0.18rem;
				&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
				   color: #BBBBBB;
				}
				&::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
				    color: #BBBBBB;
				}
				&::-moz-placeholder { /* Mozilla Firefox 19+ */
				    color: #BBBBBB;
				}
				&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				    color: #BBBBBB;
				}
			}
			.form_phone{
				width:100%;
				height:100%;
				position: absolute;
				left:0;
				top:0;
				display:flex;
				align-items:center;
				.form_phone_select{
					width:1.2rem;
					height:100%;
					display:flex;
					align-items:center;
					padding-left:0.2rem;
					font-size:0.18rem;
					justify-content: space-between;
					img{
						height:0.1rem;
						margin-left:0.075rem;
					}
					span{
						width:0.01rem;
						height:0.16rem;
						background:#BBBBBB;
						margin: 0 0.16rem;
					}
				}
				.phone_select{
					flex:1;
					height:100%;
					border:none;
					outline:none;
					padding:0 0.2rem 0 0;
					background:none;
					color:#D6AC58;
					font-size:0.18rem;
					&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
					   color: #BBBBBB;
					}
					&::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
					    color: #BBBBBB;
					}
					&::-moz-placeholder { /* Mozilla Firefox 19+ */
					    color: #BBBBBB;
					}
					&:-ms-input-placeholder { /* Internet Explorer 10-11 */
					    color: #BBBBBB;
					}
					&::-webkit-outer-spin-button, &::-webkit-inner-spin-button { 
						-webkit-appearance: none; 
					}
					-moz-appearance: textfield;
				}
			}
		}
		.form_inlin.cur{
			border:0.01rem solid #D6AC58;
			background:#fff;
			.form_border{
				border-color:#D6AC58;
				background:#fff;
			}
			.form_middle{
				.form_title{
					color:#D6AC58;
				}
			}
			.placeholder{
				font-size: 0.16rem;
				color: #D6AC58;
				left: 0.48rem;
				top: 0;
				z-index: 10;
				padding: 0 0.1rem;
				span{
					display:block;
					margin-top: 0.08rem;
					z-index:1;
				}
				p{
					z-index:1
				}
				.color{
					height: calc(50% + 0.01rem );
					background: #fff;
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					opacty:1;
				}
			}
			.placeholder.phone{
				left: 0.48rem;
			}
		}
		.form_select{
			width:100%;
			min-height:0.6rem;
			display:flex;
			position: relative;
			border:0.01rem solid #D6AC58;
			border-radius:0.05rem;
			margin-bottom:0.1rem;
			background:#fff;
			.form_select_p{
				width:100%;
				padding:0 0.2rem;
				line-height:0.6rem;
				font-size:0.18rem;
				background:none;
				color:#D6AC58;
				display:flex;
				align-items:center;
				justify-content: space-between;
				img{
					height:0.1rem;
					transition: all 0.2s;
				}
				.cur{
					transform: rotate(90deg);
				}
			}
		}
		.form_select_option{
			width:100%;
			height:0;
			overflow:hidden;
			transition: all 0.2s;
			p{
				width:100%;
				line-height:0.5rem;
				color:#90752D;
				padding:0 0.16rem;
				border-radius:0.05rem;
				font-size:0.18rem;
			}
			.cur{
				background:#F1CD87;
			}
		}
		.form_select_option.cur{
			height:1.7rem;
			overflow-y:scroll;
			margin-bottom:0.1rem;
			border-radius:0.05rem;
			border:0.01rem solid #D6AC58;
			background:#fff;
			padding:0.18rem 0.07rem;
		}
		.form_textarea{
			width:100%;
			min-height:1rem;
			max-width:100%;
			outline:none;
			border:0.01rem solid #BBBBBB;
			background:none;
			padding:0.1rem 0.2rem;
			border-radius:0.05rem;
			margin-bottom:0.3rem;
			font-size:0.18rem;
			color:#D6AC58;
			&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			   color: #BBBBBB;
			}
			&::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			    color: #BBBBBB;
			}
			&::-moz-placeholder { /* Mozilla Firefox 19+ */
			    color: #BBBBBB;
			}
			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			    color: #BBBBBB;
			}
		}
		.form_textarea.cur{
			border-color:#D6AC58;
			background:#fff;
		}
		.form_sub{
			width:100%;
			background:#D6AC58;
			color:#fff;
			height:0.5rem;
			border:none;
			outline:none;
			border-radius:0.05rem;
			margin-bottom:0.5rem;
			font-size:0.16rem;
		}
		.form_title_block{
			width:100%;
			font-size:0.16rem;
			color:#9C9C9C;
			margin-bottom:0.06rem;
		}
	}
	@media (min-width: 750px){
		.max-box{
			max-width:7.2rem;
			margin:0 auto 0 auto;
			.lng_select{
				position: relative;
				height:0.5rem;
				>div{
					right: 0;
					top: 0.09rem;
				}
			}
		}
	}
`
const basicColumns = [
  [
    { label: '+1', value: '+1' },
    { label: '+852', value: '+852' },
    { label: '+886', value: '+886' },
    { label: '+86', value: '+86' },
  ]
]
function FormList (props){
	let {t, i18n} = useTranslation()
	const lng = window.localStorage.getItem('i18nextLng')
	let title = document.getElementById('nav_title')
	
	const [visible, setVisible] = useState(false)
	const [agentSelect, setAgentSelect] = useState(false); // 弹出经纪人选择框
	const [fname, setFname] = useState(''); // 姓名
	const [lname, setLname] = useState(''); // 姓名
	const [email, setEmail] = useState(''); // 邮箱
	const [phone, setPhone ] = useState(''); // 电话
	const [wechat, setWechat] = useState(''); // 微信号
	const [agent, setAgent] = useState(`${lng === 'en'?'none':lng === 'zh-CN'?'无':'無'}`); // 选择的经纪人
	const [agentID, setAgentID] = useState(null); // 选择的经纪人ID
	const [textarea, setTextarea] = useState(''); // 文本域
	const [areaCode, setAreaCode] = useState('+1'); // 区号
	const [agentList, setAgentList] = useState([]); // 获取的经纪人列表
	
	const [inpFname, setInpFname] = useState(false); // 是否输入姓名
	const [inpLname, setInpLname] = useState(false); // 是否输入姓名
	const [inpEmail, setInpEmail] = useState(false); // 是否输入邮箱
	const [inpPhone, setInpPhone ] = useState(false); // 是否输入电话
	const [inpWechat, setInpWechat] = useState(false); // 是否输入微信号
	const [inpTextarea, setInpTextarea] = useState([]); // 是否输入文本域
	
	const [fnameFocus,setFnameFocus] = useState(t('form.LegalName')) // 获取焦点后姓名
	const [lnameFocus, setLnameFocus] = useState(t('form.LegalName')); // 获取焦点后姓名
	
	const fnameRef = useRef(); // 姓名输入框实例
	const lnameRef = useRef(); // 姓名输入框实例
	const emailRef = useRef(); // 邮箱输入框实例
	const phoneRef = useRef(); // 电话输入框实例
	const wechatRef = useRef(); // 微信号输入框实例
	const agentRef = useRef(); // 经纪人输入框实例
	const textareaRef = useRef(); // 文本域实例
	
	useEffect(() => {
		if(lng === 'zh-CN'){
			title.innerText=`PresaleVIP.com 楼花王`
		}else{
			title.innerText=`PresaleVIP.com 樓花王`
		}
		get(`/wap/form/list`).then((res) => {
			setAgentList(res.data)
	    });
		
	}, []);
	// 输入框获取焦点
	function focus (e){
		if(e === 1){
			setInpFname(true)
			setFnameFocus(t('form.FirstName'))
		}else if(e === 2){
			setInpLname(true)
			setLnameFocus(t('form.LastName'))
		}else if(e === 3){
			setInpEmail(true)
		}else if(e === 4){
			setInpPhone(true)
		}else if(e === 5){
			setInpWechat(true)
		}else if(e === 6){
			setInpTextarea(true)
		}
	}
	// 输入框失去焦点
	function Blur (e){
		if(e === 1){
			if(fname === ''){
				setInpFname(false)
				setFnameFocus(t('form.LegalName'))
			}
		}else if(e === 2){
			if(lname === ''){
				setInpLname(false)
				setLnameFocus(t('form.LegalName'))
			}
		}else if(e === 3){
			if(email === ''){
				setInpEmail(false)
			}
		}else if(e === 4){
			if(phone === ''){
				setInpPhone(false)
			}
		}else if(e === 5){
			if(wechat === ''){
				setInpWechat(false)
			}
		}else if(e === 6){
			if(textarea === ''){
				setInpTextarea(false)
			}
		}
	}
	// 提交表单
	const handleSubmit = (event) => {
		event.preventDefault();
		
		if(fname.replace(/[, ]/g,'') !== ''){
			if(lname.replace(/[, ]/g,'') !== ''){
				if(email !== ''){
					if(validator.isEmail(email)){
						if(phone !== ''){
							Toast.show({
								content: `${t('form.tips.loading')}`,
								duration: 0,
								icon: 'loading',
								maskClickable: false,
							})
							post(`/wap/registration/add`,{
								isBuilding:props.location.state.type,
								presaleId:props.location.state.houseID,
								firstName:fname,
								lastName:lname,
								email:email,
								phone:areaCode+phone,
								wechatId:wechat,
								teamMemberId:agentID,
								additionalComments:textarea,
								language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`,
							}).then((res) => {
								if(res.code === 0){
									Toast.clear()
									Dialog.alert({
										content: `${t('form.SuccessfulSubmission')}`,
										confirmText:`${t('form.tips.Determine')}`,
										onConfirm: () => {
											props.history.goBack()
										},
									})
								}else{
									Toast.clear()
									console.log(res.code.msg)
								}
							}).catch((error) => {
								console.log(error)
							});
						}else{
							Toast.show({
								content: `${t('form.tips.phone')}`,
							})
						}
					}else{
						Toast.show({
							content: `${t('form.tips.MailboxFormatError')}`,
						})
					}
				}else{
					Toast.show({
						content: `${t('form.tips.email')}`,
					})
				}
			}else{
				Toast.show({
					content: `${t('form.tips.lastName')}`,
				})
			}
		}else{
			Toast.show({
				content: `${t('form.tips.firstName')}`,
			})
		}
	}
	return(
		<Box>
			<div className="max-box">
				<div className="top">
					<div className="left">
						<img src={back} className="icon_back" alt='back'
							onClick={
								()=>{
									props.history.goBack()
								}
							}
						 />
						<p>{t('form.linkBtn')}</p>
					</div>
					
					<div className="lng_select">
						<LngSet />
					</div>
				</div>
				<form className="form_box" onSubmit={handleSubmit}>
					<div className={inpFname === true?'form_inlin cur':'form_inlin'}>
						<input className="form_input" autoComplete="off" type="text" name="first_name" value={fname} onChange={
							(e)=>{
								setFname(fnameRef.current.value)
							}
						} ref={fnameRef} onFocus={()=>focus(1)} onBlur={()=>Blur(1)} />
						<div className="placeholder"><span>*</span><p>{fnameFocus}</p><div className="color"></div></div>
					</div>
					<div className={inpLname === true?'form_inlin cur':'form_inlin'}>
						<input className="form_input" autoComplete="off" type="text" name="last_name" value={lname} onChange={
							(e)=>{
								setLname(lnameRef.current.value)
							}
						} ref={lnameRef} onFocus={()=>focus(2)} onBlur={()=>Blur(2)}  />
						<div className="placeholder"><span>*</span><p>{lnameFocus}</p><div className="color"></div></div>
					</div>
					<div className={inpEmail === true?'form_inlin cur':'form_inlin'}>
						<input className="form_input" autoComplete="off" type="text" name="email" value={email} onChange={
							(e)=>{
								setEmail(emailRef.current.value)
							}
						} ref={emailRef} onFocus={()=>focus(3)} onBlur={()=>Blur(3)} />
						<div className="placeholder"><span>*</span><p>{t('form.Email')}</p><div className="color"></div></div>
					</div>
					<div className={inpPhone === true?'form_inlin cur':'form_inlin'}>
						<div className="form_phone">
							<div className="form_phone_select"
								onClick={() => {
									setVisible(true)
								}}
							>
								<p style={{color:(phone === '')?null:'#D6AC58'}}>{areaCode}</p>
								<img src={icon_p} alt="icon" />
								<span></span>
							</div>
							<Picker
								columns={basicColumns}
								visible={visible}
								onClose={() => {
								  setVisible(false)
								}}
								onConfirm={(e) => {
								  setAreaCode(e)
								}}
								onSelect={(val, extend) => {
								  console.log('onSelect', val, extend.items)
								}}
							>
							</Picker>
							<input className="phone_select" autoComplete="off" type="number" name="phone" value={phone} onChange={
								(e)=>{
									setPhone(phoneRef.current.value)
								}
								
							} ref={phoneRef} onFocus={()=>focus(4)} onBlur={()=>Blur(4)} />
							<div className="placeholder phone"><span>*</span><p>{t('form.Phone')}</p><div className="color"></div></div>
						</div>
						
					</div>
					<div className={inpWechat === true?'form_inlin cur':'form_inlin'} style={{marginBottom:'0.1rem'}}>
						<input className="form_input" autoComplete="off" type="text" name="wechat" value={wechat} onChange={
							(e)=>{
								setWechat(wechatRef.current.value)
							}
						} ref={wechatRef} onFocus={()=>focus(5)} onBlur={()=>Blur(5)} />
						<div className="placeholder"><p>{t('form.WechatID')}</p><div className="color"></div></div>
					</div>
					<p className="form_title_block">{t('form.TeamMember')}</p>
					<div className="form_select"
						onClick={()=>{
							if(agentSelect === true){
								setAgentSelect(false)
							}else{
								setAgentSelect(true)
							}
							
						}}
					>
						<div className="form_select_p">
							{agent}
							<img className={agentSelect === true ? 'cur':null} src={icon} alt='icon' />
						</div>
					</div>
					<div className={agentSelect === true?'form_select_option cur':'form_select_option'}>
						<p
							className={agentID === null?'cur':null}
							onClick={()=>{
								setAgent(`${lng === 'en'?'none':lng === 'zh-CN'?'无':'無'}`)
								setAgentSelect(false)
								setAgentID(null)
							}}
						>{lng === 'en'?'none':lng === 'zh-CN'?'无':'無'}</p>
						{
							
							agentList.map((item, index) => (
								<p
									className={agentID === item.id?'cur':null}
									onClick={()=>{
										setAgent(item.name)
										setAgentSelect(false)
										setAgentID(item.id)
									}}
									key={index}
								>{item.name}</p>
							))
						}
						
					</div>
					<p className="form_title_block">{t('form.AdditionalComments')}</p>
					<textarea className={inpTextarea === true?'form_textarea cur':'form_textarea'} autoComplete="off" type="text" name="textarea" value={textarea} onChange={
						(e)=>{
							setTextarea(textareaRef.current.value)
						}
					} ref={textareaRef} placeholder={t('form.AdditionalComments')} onFocus={()=>focus(6)} onBlur={()=>Blur(6)} />
					<button className="form_sub">{t('form.Submit')}</button>
				</form>
			</div>
		</Box>
	)
}
export default FormList