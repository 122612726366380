import React from "react";
import { key } from "../../../service/config";
import {GoogleMap,withScriptjs,withGoogleMap,Marker} from "react-google-maps";
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'
import { compose, withProps, withHandlers } from 'recompose'

const m1 = require("../../../assets/img/m1.png");
const m2 = require("../../../assets/img/m2.png");
const m3 = require("../../../assets/img/m3.png");

const MapWithAMarkerClusterer = compose(
	withProps({
		googleMapURL:
			`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `calc(100vh - 1.8rem)` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withHandlers({
        onMarkerClustererClick: (params) => markerClusterer => {
            const clickedMarkers = markerClusterer.getMarkers()
            let first = JSON.parse(clickedMarkers[0].cursor);
            let sameLatLng = true;
			console.log(clickedMarkers[0])
            for (let i = 0; i < clickedMarkers.length; ++i) {
                let data = JSON.parse(clickedMarkers[i].cursor);
                if (data?.latitude !== first?.latitude || data?.longitude !== first?.longitude) {
                    sameLatLng = false;
                }
            }
            var presaleData = [];
            if (sameLatLng) {
                console.log('-----sameLatLng-----')
                if (clickedMarkers.length <= 10) {
                    for (let i = 0; i < clickedMarkers.length; ++i) {
                        presaleData.push(JSON.parse(clickedMarkers[i].cursor));
                    }
                } else {
                    clickedMarkers.sort(function () {
                        return Math.random() - 0.5;
                    });
                    for (let i = 0; i < 10; ++i) {
                        presaleData.push(JSON.parse(clickedMarkers[i].cursor));
                    }
                }
                params.getData(presaleData);
            } else {
                if (clickedMarkers.length <= 10) {
                    console.log('-----executeClickCluster-----');
                    for (let i = 0; i < clickedMarkers.length; ++i) {
                        var data = JSON.parse(clickedMarkers[i].cursor);
                        presaleData.push(data);
                    }
                    params.getData(presaleData);
                }
            }
		}
	}),
	withScriptjs,
	withGoogleMap
)(props => {
    return (
        <GoogleMap
            defaultZoom={11}
            defaultCenter={{ lat: 49.254924, lng: -123.0586233 }}
        >
            <MarkerClusterer
                onClick={props.onMarkerClustererClick}
                averageCenter
                enableRetinaIcons
                styles={
                    [{
                        url: m1.default,
                        width: 30,
                        height: 30,
                    }, {
                        url: m2.default,
                        width: 32,
                        height: 32,
                    }, {
                        url: m3.default,
                        width: 40,
                        height: 40,
                    }]
                }
                gridSize={60}
            >
                {props.markers.map((marker, i) => (
                    <Marker
                        key={marker.id}
                        position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude)}}
                        onClick={
                            (e) => {
                                // window.location.href=`${props.detailTo}/${marker.id}`
								props.history.push({pathname: `${props.detailTo}/${marker.id}`,state : { ishistory: true }})
							}
                        }
                        
						// title={
						// 	JSON.stringify(
						// 		{
						// 			builtYear:marker.builtYear,
						// 			city:marker.city,
						// 			coverPic:marker.coverPic,
						// 			id:marker.id,
						// 			name:marker.name,
						// 			price:marker.price
						// 		},
						// 	)
						// }
						cursor={
								JSON.stringify(
									{
										builtYear:marker.builtYear,
										city:marker.city,
										coverPic:marker.coverPic,
										id:marker.id,
										name:marker.name,
										price:marker.price
									},
								)
						}
                    />
                ))}
            </MarkerClusterer>
            {
                //<TrafficLayer autoUpdate />
            }
        </GoogleMap>
    )
})

class Map extends React.PureComponent {
	constructor(props: any) {
		super(props);
		console.log(props)
   }
	componentWillMount() {
		this.setState({ markers: [] })
    }
    
    getDataFromChildren = (params) => {
        this.props.getData(params);
    }

    componentDidMount() {
		this.setState({ markers: Location.location })
    }
	render() {
		return (
			<>
				<MapWithAMarkerClusterer
					markers={this.props.list || []}
					getData={this.getDataFromChildren}
					detailTo = {this.props.detailTo}
					{...this.props}
				/>
				{
				}
			</>
		)
		
	}
}

export default Map