import React from "react";
import { compose, withProps } from "recompose";
import {withScriptjs,withGoogleMap,GoogleMap,Marker,TrafficLayer} from "react-google-maps";
import { key } from "../../service/config";
// import mapStyles from './mapStyles'

const HospitalMarker = require('../../assets/img/marker/Hospital.png');
const ShoppingMallMarker = require('../../assets/img/marker/ShoppingMall.png');
const PublicTransportationMarker = require('../../assets/img/marker/PublicTransportation.png');
const LocationMarker = require('../../assets/img/marker/location.png');

const MyMapComponent = compose(
	withProps({
		// mapId: `4d6d9e29822d13d`,
		googleMapURL:
			`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `2.2rem` }} />,
		mapElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
)(props => {
    // const [location, setLocation] = useState({});
    return (
        <GoogleMap 
            defaultZoom={14}
            center={{ lat: props.location.lat, lng: props.location.lng }}
            // defaultCenter={{ lat: 49.24913249999999, lng: -123.1056808 }}
            // defaultCenter={{ lat: props.location.lat, lng: props.location.lng }}
            // options={{ styles: mapStyles }}
        >
            {
                props.isMarkerShown && props.nearby.currentNearby.map((item, index) => (
                    <Marker
                        position={{ lat: Number(item.latitude), lng: Number(item.longitude) }}
                        key={index}
                        // icon='../../assets/img/marker/Hospital.png'
                        icon={{
                            url: props.type === 'Hospital' ? HospitalMarker.default : props.type === 'ShoppingMall' ? ShoppingMallMarker.default : PublicTransportationMarker.default,
                            size: {
                                width: 50,
                                height: 50
                            }
                        }}
                    />
                ))
            }
            {
                <Marker
                    position={{ lat: Number(props.location.lat), lng: Number(props.location.lng) }}
                    // position={location}
                    icon={{
                        url: LocationMarker.default,
                        size: {
                            width: 50,
                            height: 50
                        }
                    }}
                />
            }
            <TrafficLayer autoUpdate />
        </GoogleMap>
    )
});

class Map extends React.PureComponent {
    render() {
		return (
			<MyMapComponent isMarkerShown nearby={this.props} location={this.props?.location} type={this.props.type} />
		)
		
	}
}
export default Map
