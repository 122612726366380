import React, { useState,useEffect } from 'react'
import { withRouter } from "react-router";
import TabBar from '../../components/Tabbar'
import { List, Mask } from 'antd-mobile'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { get } from '../../service';

import bg from "../../assets/img/bg-01.png";
import logoFt from "../../assets/img/logo-ft.png";
import logoJt from "../../assets/img/logo-cn.png";
import set1 from "../../assets/img/set-icon-01.png";
import set2 from "../../assets/img/set-icon-02.png";
import set3 from "../../assets/img/set-icon-03.png";
import set4 from "../../assets/img/set-icon-04.png";
import set5 from "../../assets/img/set-icon-05.png";
import ewm from "../../assets/img/ewm-wx.jpg";
import app from "../../assets/img/ewm-app.jpg";
const Box = styled.div`
	width:100%;
	padding-bottom:1rem;
	.bg_box{
		width:100%;
		height:4.48rem;
		background:url(${bg});
		display:flex;
		background-size:cover;
		background-position:center;
		position: relative;
		.top{
			margin-top:0.15rem;
			font-size:0.24rem;
			font-weight: 550;
			margin-bottom:0.14rem;
			padding-left:0.2rem;
			color:#fff;
		}
		.logo{
			height:0.81rem;
			position: absolute;
			top:1.3rem;
			left:50%;
			transform: translateX(-50%);
		}
	}
	.list{
		width:100%;
		padding:0 0.2rem;
		z-index:0;
		position: relative;
		.list_ul{
			margin-top:-0.7rem;
			.adm-list-item.adm-plain-anchor{
				height:0.7rem;
				padding:0 0.05rem;
				.adm-list-item-content{
					padding:0 0.16rem;
					height:0.7rem;
					font-size:0.18rem;
					border-bottom:0.01rem solid #E8E5DD;
					.adm-list-item-content-prefix{
						display:flex;
						align-items:center;
						.list_icon{
							width:0.24rem;
							height:0.24rem;
						}
					}
					.lng_text{
						color:#666666;
						font-size:0.14rem;
					}
				}
			}
		}
	}
	.img_ewm{
		width:2.9rem;
		height:auto;
		background:#fff;
		border-radius:0.18rem;
		border:0.01rem solid #707070;
		display:flex;
		flex-direction: column;
		align-items:center;
		padding:0.2rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		img{
			width:100%;
			margin-bottom:0.11rem;
		}
	}
	@media (min-width: 750px){
		.max-box{
			max-width:7.2rem;
			margin:0 auto 0 auto;
		}
	}
`;

function Contact_us(props){
	const lng = window.localStorage.getItem('i18nextLng')
    const [visible, setVisible] = useState(false)
    const [textUnderQrCode, setTextUnderQrCode] = useState();
	const [wx,setWx] = useState(true);
	let {t} = useTranslation()
	useEffect(() => {
		let title = document.getElementById('nav_title')
		let elng = window.localStorage.getItem('i18nextLng')
		if(elng === 'zh-CN'){
			title.innerText=`PresaleVIP.com 楼花王`
		}else{
			title.innerText=`PresaleVIP.com 樓花王`
		}
		// 微信分享
		get(`/wechar/share/`,{
			url:window.location.href
		}).then( res => {
			window.wx.config({
			  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			  appId:  res.data.appId, // 必填，公众号的唯一标识
			  timestamp: res.data.timestamp, // 必填，生成签名的时间戳
			  nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
			  signature: res.data.signature,// 必填，签名，见附录1
			  jsApiList: [
				'updateAppMessageShareData',
				'updateTimelineShareData',
			  ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			})
		})
		// 判断是否微信浏览器
		const ua = window.navigator.userAgent.toLowerCase()
		if (ua.indexOf('micromessenger') < 0) return false
		// 开局调用分享
		window.wx.ready(() => {
			//分享给朋友
			window.wx.updateAppMessageShareData({
				title: '楼花王 | 大温地区最全楼花信息平台', // 分享标题
				desc: '第一选房 · 第一价格 · 第一优惠 · 第一锁定', // 分享描述
				link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: `https://www.presalevip.com${bg}`, // 分享图标
				success: function () {
				  // 用户确认分享后执行的回调函数
				},
				cancel: function () {
				  // 用户取消分享后执行的回调函数
				}
			})
			
			  //分享到朋友圈
			window.wx.updateTimelineShareData({
				title: '楼花王 | 大温地区最全楼花信息平台', // 分享标题
				link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: `https://www.presalevip.com${bg}`, // 分享图标
				success: function () {
				  // 用户确认分享后执行的回调函数
				},
				cancel: function () {
				  // 用户取消分享后执行的回调函数
				}
			})
		})
	}, []);
	return(
		<Box>
			<div className="max-box">
				<div className="bg_box">
					<div className="top">{t('ContactUs')}</div>
					<img className="logo" src={lng === 'zh-CN'?logoJt:logoFt} alt="logo" />
				</div>
				<div className="list">
					<List className="list_ul">
						<List.Item
							prefix={
								<img className="list_icon" src={set1} alt='icon' />
							}
							extra={
								<p className="lng_text">{lng === 'zh-TW'?'中文繁體':lng === 'zh-CN'?'中文简体':'English'}</p>
							}
						 onClick={() => {props.history.push({pathname: `/set_language`,state : { ishistory: true }} )}}
						>
							{t('contact_us.set_lng')}
						</List.Item>
						
						<List.Item
							prefix={
								<img className="list_icon" src={set2} alt='icon' />
							}
						 onClick={() => {
							 window.location.href = `tel:+1-778-378-2888`;
						 }}
						>
							{t('contact_us.mobile')}
						</List.Item>
						
						<List.Item
							prefix={
								<img className="list_icon" src={set3} alt='icon' />
							}
							onClick={() => {
								setVisible(true);
								setWx(true)
								setTextUnderQrCode("wechat");
							}}
						>
							{t('contact_us.wechat')}
						</List.Item>
						<List.Item
							prefix={
								<img className="list_icon" src={set4} alt='icon' />
							}
						 onClick={() => {
							 // window.location.href = `https://api.whatsapp.com/send?phone=+1-778-378-2888&text=Hello`;
							 setVisible(true);
							 setWx(false)
							 setTextUnderQrCode('whatsapp')
						 }}
						>
							{t('contact_us.whatsapp')}
						</List.Item>
						
						<List.Item
							prefix={
								<img className="list_icon" src={set5} alt='icon' />
							}
							onClick={() => {
								setVisible(true);
								setWx(true)
								setTextUnderQrCode("wechatOfficialAccount");
							}}
						>
							{t('contact_us.wechat_num')}
						</List.Item>
					</List>
				</div>
			</div>
			<TabBar />
			<Mask visible={visible} onMaskClick={() => setVisible(false)}>
				<div className="img_ewm">
					<img src={wx === true ? ewm : app} alt="ewm" />
                    {/* <p>{t('contact_us.wechat')}</p> */}
                    <p style={{textAlign: 'center'}}>
                        {textUnderQrCode === 'wechat' ? t('contact_us.scanWechatCodeText') :textUnderQrCode === 'whatsapp' ? t('contact_us.scanWhatsappCodeText') : t('contact_us.scanWechatOfficialAccountCodeText')}
                    </p>
				</div>
			</Mask>
		</Box>
	)
}
export default withRouter(Contact_us)