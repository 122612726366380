import React, { memo } from "react";
import { renderRoutes } from "react-router-config";
import { withRouter } from "react-router";
import routes from "./routes/index";
function App (props){
	return (
		<div>
			{renderRoutes(routes)}
		</div>
	)
}
export default withRouter(memo(App));