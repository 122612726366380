import React,{useState} from 'react'
import { withRouter } from "react-router";
import styled from 'styled-components'
import { Mask } from 'antd-mobile'
import { useTranslation } from 'react-i18next';
import icon from "../../assets/img/icon-17.png";
import icon2 from "../../assets/img/icon-18.png";
import icon3 from "../../assets/img/icon-19.png";
import icon4 from "../../assets/img/icon-09.png";
import icon5 from "../../assets/img/icon-15.png";
import ewm from "../../assets/img/ewm-wx.jpg";
import app from "../../assets/img/ewm-app.jpg";
const Box = styled.div`
	width:100%;
	height:1rem;
	display:flex;
	align-items:center;
	padding:0 0.2rem;
	background:#fff;
	position:fixed;
	bottom:0;
	left:0;
	z-index:10;
	.list_ul{
		width:100%;
		height:0.64rem;
		border-radius:0.05rem;
		border:0.01rem solid #D6AC58;
		border-right:none;
		display:flex;
		align-items:center;
		background:#fff;
		justify-content: space-between;
		.list_li{
			width:25%;
			height:0.64rem;
			font-size:${window.localStorage.getItem('i18nextLng') === 'en'?'0.12rem':'0.14rem'};
			color:#000;
			display:flex;
			flex-direction: column;
			align-items:center;
			justify-content:center;
			border-right:0.01rem dashed #D6CDBA;
			white-space: nowrap;
			cursor: pointer;
			p{
				transform:${window.localStorage.getItem('i18nextLng') === 'en'?'scale(0.9)':''}; ;
			}
			img{
				height:0.22rem;
				margin-bottom:${window.localStorage.getItem('i18nextLng') === 'en'?'0.08rem':'0.05rem'};
			}
			&:last-child,&:nth-last-child(2){
				border:none;
			}
		}
		.list_li.yellow{
			background:#D6AC58;
			color:#fff;
			border-radius: 0 0.05rem 0.05rem 0;
		}
	}
`;
const Black = styled.div`
	.adm-mask{
		z-index:9;
		.img_ewm{
			width:2.9rem;
			height:auto;
			background:#fff;
			border-radius:0.18rem;
			font-size:0.2rem;
			border:0.01rem solid #707070;
			display:flex;
			flex-direction: column;
			align-items:center;
			padding:0.2rem;
			position: absolute;
			left: 50%;
			bottom:1.2rem;
			transform: translateX(-50%);
			text-align:center;
			.ewm{
				width:100%;
				margin-bottom:0.11rem;
			}
			p{
				margin-bottom:0.22rem;
			}
			.icon{
				height:0.42rem;
				position: absolute;
				bottom: -0.20rem;
			}
		}
	}
	
`;
function Service (props) {
	const [visible, setVisible] = useState(false)
	const [wx,setWx] = useState(true);
	let {t} = useTranslation()
	const [type, setType] = useState(props.type)
	return (
		<>
		<Box>
			<div className="list_ul">
				<div className="list_li" onClick={() =>{setVisible(true);setWx(true)}}>
					<img src={icon} alt="icon" />
					<p>{t('details.wechat')}</p>
				</div>
				<div className="list_li" 
					onClick={() => {
						// window.location.href = `https://api.whatsapp.com/send?phone=+1-778-378-2888&text=Hello`;
						setVisible(true);
						setWx(false)
					}}
				>
					<img src={icon2} alt="icon" />
					<p>Whatsapp</p>
				</div>
				<div className="list_li" onClick={() => {
					window.location.href = `tel:+1-778-378-2888`;
				}}>
					<img src={icon3} alt="icon" />
					<p>{t('details.mobile')}</p>
				</div>
				<div className="list_li yellow" onClick={() => {
					// props.history.push(`/form/${props.type}`)
					props.history.push(
						{pathname:'/form'},
						{type:props.type,houseID:props.houseID}
					)
				}}>
					<img src={icon5} alt="icon" />
					<p>{t('form.linkBtn')}</p>
				</div>
			</div>
		</Box>
		<Black>
			<Mask visible={visible} onMaskClick={() => setVisible(false)}>
				<div className="img_ewm">
					<img className="ewm" src={wx === true ? ewm : app} alt="ewm" />
					<p>{wx === true ? t('contact_us.scanWechatCodeText') : t('contact_us.scanWhatsappCodeText') }</p>
					<img className="icon" src={icon4} alt='icon' />
				</div>
			</Mask>
		</Black>
		</>
	)
	
}
export default withRouter(Service)