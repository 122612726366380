import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import resources from "./resource.js"
i18n
	.use(Backend)
	.use(LanguageDetector) //嗅探当前浏览器语言
	.use(initReactI18next) //init i18next
	.init({
		//引入资源文件
		resources: resources,
		lng: localStorage.getItem("i18nextLng"),
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		react: {
			useSuspense: false
		}
})

export default i18n;