import React from 'react'

/**
 * 我们暂定认为传过来的腾讯视频的链接形式为：https://v.qq.com/x/page/{我们需要的视频id}.html
 * @param {string} url 需要被解析的字符串
 */
function parseVedioURL(url) {
    if (url === null || url === undefined || url === "") {
        return false;
    }
    var index = url.lastIndexOf("page/");
    if (index < 0) {
        return false;
    }
    console.log('urlAfterParse---', url.substring(index + 5, url.length - 5));
    return url.substring(index + 5, url.length - 5);
}

function VideoIframe (props) {
	return (
		<iframe 
			width="100%"
			height="500px"
			src={`//v.qq.com/txp/iframe/player.html?vid=${parseVedioURL(props.link)}`}
			// src={props.link}
			allowFullScreen={true} //是否在视频下方显示全屏按钮
		>
		</iframe>
	)
}
export default VideoIframe