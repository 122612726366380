import React,{useState,useEffect,useRef} from 'react'
import styled from 'styled-components'
import { ImageViewer,Image,Swiper,Dialog} from 'antd-mobile';
import Service from '../../components/Customer_service'
import Surroundings from '../../components/Surroundings'
import Hotquestion from '../../components/Hot_question'
import LngSet from '../../components/Lng_set'
import { post , get} from '../../service'
import { ImageURL } from "../../service/config";
import { useTranslation } from 'react-i18next';
import Tencent from '../../components/Tencent'
import YouTube from '../../components/YouTube'

import icon from "../../assets/img/icon-04.png";
import icon2 from "../../assets/img/icon-05.png";
import icon3 from "../../assets/img/icon-back.png";
import icon4 from "../../assets/img/icon-01.png";
import icon5 from "../../assets/img/icon-11.png";
import icon6 from "../../assets/img/icon-12.png";
import iconjt from "../../assets/img/icon-Arrows.svg";
const Box = styled.div`
	font-size:0.24rem;
	padding-bottom:1.2rem;
	.fixed_top{
		width:100%;
		max-width:7.2rem;
		position:fixed;
		top:0;
		z-index:100;
		.back{
			height:0.36rem;
			position: absolute;
			left:0.2rem;
			top:0.2rem;
			z-index:10;
			box-shadow: 0 0 0.06rem rgba(102,102,102,0.2);
			border-radius:0.09rem;
		}
		>div{
			top:0.2rem;
			right:0.2rem;
		}
	}
	.banner_top{
		position: relative;
		height:3.13rem;
		.right{
			position: absolute;
			right: -50px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			display:none;
		}
		.left{
			position: absolute;
			left: -50px;
			top: 50%;
			transform: translateY(-50%) rotate(180deg);;
			cursor: pointer;
			display:none;
		}
		.adm-swiper{
			height:100%;
			.adm-swiper-indicator{
				transform: translateX(-50%);
				.adm-page-indicator{
					.adm-page-indicator-dot{
						width:0.14rem;
						height:0.08rem;
						border-radius:0.07rem;
						background:rgba(255,255,255,0.52);
						transition: all .2s;
					}
					.adm-page-indicator-dot-active{
						width:0.48rem;
						background:#fff;
					}
				}
			}
			.adm-swiper-track{
				height:100%;
				.adm-swiper-track-inner{
					height:100%;
					.adm-swiper-slide{
						.adm-swiper-item{
							height:100%;
							>div{
								width:100%;
								height:100%;
							}
							iframe{
								width:100%;
								height:100%;
							}
							.video-bg{
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
		.play{
			width:0.7rem;
			position: absolute;
			left: 50%;
			top: 1.53rem;
			transform: translateX(-50%);
		}
	}
	
	.banner_bottom{
		width:100%;
		background:#fff;
		display:flex;
		padding-top:0.23rem;
		justify-content: center;
		.banner-bottom-swiper{
			width:calc(100% - 0.84rem); 
			display:flex;
			align-items:center;
			justify-content: center;
			.bottom-swiper-slide{
				width: 0.48rem;
				height: 0.48rem;
				border-radius:0.05rem;
				position: relative;
				overflow:hidden;
				border:0.03rem solid transparent;
				.border{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 0.05rem;
					z-index:1;
				}
				.adm-image{
					border-radius:0.05rem;
				}
				.num{
					width:100%;
					height:100%;
					position: absolute;
					top: 0;
					left: 0;
					background: rgba(0,0,0,0.4);
					font-size:0.18rem;
					color:#fff;
					display:flex;
					align-items:center;
					justify-content: center;
					border-radius: 0.05rem;
				}
			}
			.bottom-swiper-slide.cur{
				.border{
					border:0.03rem solid #D6AC58;
				}
			}
		}
	}
	.info{
		width:100%;
		padding:0 0.2rem;
		background:#fff;
		display:flex;
		flex-direction: column;
		.info_top{
			width:100%;
			display:flex;
			flex-direction: column;
			align-items:center;
			padding:0.2rem 0 0.1rem 0;
			.info_title{
				font-size:0.24rem;
				line-height:0.33rem;
				font-weight: 550;
			}
			.info_addr{
				color:#666666;
				font-size:0.16rem;
				display:flex;
				align-items:center;
				img{
					height:0.15rem;
					margin-right:0.06rem;
				}
			}
		}
		.price_box{
			width:100%;
			display:flex;
			align-items:center;
			flex-direction: column;
			border-bottom:0.01rem solid #BFC5C9;
			padding-bottom:0.1rem;
			.info_tab{
				width:100%;
				display:flex;
				align-items:center;
				justify-content: center;
				flex-wrap: wrap;
				.info_tab_li{
					display:flex;
					align-items:center;
					font-size:0.16rem;
					width:calc(50% - 0.12rem);
					justify-content: flex-end;
					margin-top:0.1rem;
					img{
						height:0.21rem;
						margin-right:0.07rem;
					}
					&:first-child{
						padding-right:0.12rem;
					}
					&:nth-last-child(1){
						margin-right:0;
						justify-content:flex-start;
					}
				}
				.info_tab_li.en{
					width:100%;
					justify-content: center;
					&:first-child{
						padding-right:0;
					}
					&:nth-last-child(1){
						justify-content:center;
					}
				}
			}
			.info_price{
				line-height:0.4rem;
				display:flex;
				align-items:baseline;
				font-size:0.16rem;
				color:#666;
				.price{
					font-size:0.28rem;
					font-weight: 550;
					color:#D6AC58;
					margin-right:0.06rem;
				}
			}
		}
		.info_bottom{
			width:100%;
			padding:0.2rem 0 0.2rem 0;
			width:100%;
			display:flex;
			align-items:center;
			flex-wrap:wrap;
			font-size:0.16rem;
			justify-content: space-between;
			border-bottom:0.01rem solid #BFC5C9;
			p{
				color:#333333;
				min-width:calc(50% - 0.05rem);
				min-height:0.36rem;
				line-height:0.3rem;
				display:flex;
				span{
					&:nth-last-child(1){
						flex:1;
					}
				}
			}
			p.cloum{
				width:100%;
				display:flex;
				span{
					&:nth-last-child(1){
						flex:1;
					}
				}
			}
		}
		.house_text{
			width:100%;
			font-size:0.14rem;
			line-height:0.22rem;
			margin-top:0.2rem;
		}
		.house_img{
			width:100%;
			display:flex;
			flex-direction: column;
			align-items:flex-start;
			.house_img_title{
				margin:0.2rem 0;
				font-size:0.16rem;
				font-weight: 550;
			}
			img{
				max-width:100%;
				margin-bottom:0.25rem;
			}
		}
	}
	.list_title{
		display:flex;
		align-items:center;
		font-size:0.2rem;
		color:#000;
		margin: 0 0.2rem;
		padding:0.2rem 0;
		font-weight: 550;
		img{
			width:0.12rem;
			margin-right:0.053rem;
		}
	}
	.gmnoprint{
		display:none;
	}
	.form_link{
		position:fixed;
		right:0;
		bottom:0.9rem;
	}
	@media (min-width: 750px){
		height:100vh;
		overflow-y:scroll;
		padding-bottom:1rem;
		&::-webkit-scrollbar{/*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
				width:14px;
				height:14px;
		}
		&::-webkit-scrollbar-button{/*滚动条两端的按钮，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置2）*/
				display:none;
		}
		&::-webkit-scrollbar-track{/*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-track-piece{/*内层轨道，滚动条中间部分（位置4）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-thumb{/*滚动条里面可以拖动的那部分（位置5）*/
			background:rgb(193,193,193);
		}
		&::-webkit-scrollbar-corner {/*边角（位置6）*/
			display:none;
		}
		&::-webkit-scrollbar-resizer  {/*定义右下角拖动块的样式（位置7）*/
			bdisplay:none;
		}
		.max-box{
			max-width:7.2rem;
			margin:0 auto 0 auto;
			.left,.right{
				display:block;
			}
		}
	}
`

const hide = {
	display:'none'
}

function queryURLParameter(url){
	let reg = /([^?&=]+)=([^?&=]+)/g
	let obj = {}
	url.replace(reg,function(){
		obj[arguments[1]] = arguments[2]
	})
	return obj
}
function OverlayVisible (props) {
	const lng = window.localStorage.getItem('i18nextLng')
	const [visible, setVisible] = useState(false)
	const [list, setList] = useState({})
	const [images, setImages] = useState([])
	const [houseType, setHouseType] = useState([])
	const [num, setNum] = useState(0)
	const [video, setVideo] = useState(false)
	const [rightImg, setRightImg] = useState();
	const [bannerImg, setBannerImg] = useState()
	const ref = useRef()
	let {t} = useTranslation()
	const Multi = () => {
	  return (
	    <>
			<ImageViewer.Multi
				visible={visible}
				onClose={() => {
					setVisible(false)
				}}
			/>
			<ImageViewer
				visible={visible}
				onClose={() => {
					setVisible(false)
				}}
			/>
	    </>
	  )
	}
	useEffect(() => {
		let title = document.getElementById('nav_title')
		let elng = window.localStorage.getItem('i18nextLng')
		if(elng === 'zh-CN'){
			title.innerText=`PresaleVIP.com 楼花王`
		}else{
			title.innerText=`PresaleVIP.com 樓花王`
		}
		post(`/wap/house/queryHouseDetail`,{
			language: `${lng === 'zh-CN'?'ZHCN':lng === 'zh-TW'?'ZHTW':'EN'}`,
			id:props.match.params.id
		}).then( res => {
			if(res.code === 0){
				setList(res.data)
				setImages(res.data.detailPicList)
				setHouseType(res.data.houseTypePicList[0])
				
				if(res.data.tencentUrl !== ''){
					if(res.data.tencentUrl !== null){
						if(lng === 'zh-CN'){
							setVideo(true)
						}
					}
				}
				if(res.data.youtubeUrl !== ''){
					if(res.data.youtubeUrl !== null){
						if(lng !== 'zh-CN'){
							setVideo(true)
						}
					}
				}
				var blist = []
				res.data?.detailPicList.forEach((item)=>{
					blist.push(item)
				});
				setBannerImg(blist)
			}else{
				Dialog.alert({
					content: (<p style={{textAlign:'center'}}>{t('msg.houseMsg')}</p>),
					confirmText:t('VIP.confirmButtonText'),
					onConfirm: () => {
						
						if(queryURLParameter(window.location.href).share === 'true'){
							props.history.push(`/`)
							console.log('来源---分享')
						}else{
							props.history.goBack(-1)
							console.log('来源---列表')
						}
					},
				})
			}
			// 微信分享
			get(`/wechar/share/`,{
				url:window.location.href
			}).then( res => {
				window.wx.config({
				  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				  appId:  res.data.appId, // 必填，公众号的唯一标识
				  timestamp: res.data.timestamp, // 必填，生成签名的时间戳
				  nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
				  signature: res.data.signature,// 必填，签名，见附录1
				  jsApiList: [
				    'updateAppMessageShareData',
				    'updateTimelineShareData',
				  ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
				})
			})
			// 判断是否微信浏览器
			const ua = window.navigator.userAgent.toLowerCase()
			if (ua.indexOf('micromessenger') < 0) return false
			// 开局调用分享
			window.wx.ready(() => {
				//分享给朋友
				window.wx.updateAppMessageShareData({
					title: `${res.data.city} 楼花 ${res.data.buildingName} | 楼花王VIP通道登记/开放中`, // 分享标题
					desc: `${res.data.buildingName} by ${res.data.builder} - 查看VIP价格 · 户型资料 · 特别优惠`, // 分享描述
					link: window.location.href+'?share=true', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
					imgUrl: res.data.coverPic, // 分享图标
					success: function () {
					  // 用户确认分享后执行的回调函数
					},
					cancel: function () {
					  // 用户取消分享后执行的回调函数
					}
				})
				
				  //分享到朋友圈
				window.wx.updateTimelineShareData({
					title: `${res.data.city} 楼花 ${res.data.buildingName} | 楼花王VIP通道登记/开放中`, // 分享标题
					link: window.location.href+'?share=true', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
					imgUrl: res.data.coverPic, // 分享图标
					success: function () {
					  // 用户确认分享后执行的回调函数
					},
					cancel: function () {
					  // 用户取消分享后执行的回调函数
					}
				})
			})
		})
		
	}, []);
	
    return (
		<Box>
			<div className="max-box">
				<div className="banner_top">
					<div className="fixed_top">
						<img className="back" src={icon3} alt="back" 
							onClick={
								()=>{
									// if(queryURLParameter(window.location.href).share === 'true'){
									if(props.location.state?.ishistory){
										props.history.goBack(-1)
									}else{
										props.history.push(`/`)
									}
								}
							} 
						/>
						<LngSet />
					</div>
					<div className="left"
						onClick={() => {
							ref.current?.swipePrev()
						}}
					><img src={iconjt} /></div>
					<Swiper
						autoplay
						onIndexChange={
							(e)=>{
								setNum(e)
								if(e > 2){
									setRightImg(images[e])
								}
							}
						}
						ref={ref}
						loop={true}
						autoplayInterval={5000}
						indicatorProps={{
							color: 'white',
						}}
					>
					{
						video === true?<Swiper.Item
							style={video === false ?hide:null}
							onClick={() => {
								props.history.push(
									{pathname:'/details_video'},
									{cnLink:list.tencentUrl,enLink:list.youtubeUrl},
								)
							}}
						>
							{
								lng === 'zh-CN'?
								<Tencent link={list.tencentUrl}></Tencent>:
								<YouTube link={list.youtubeUrl}></YouTube>
							}
							<div className="video-bg"></div>
						</Swiper.Item>:null
					}
					{
						images.map((item, index) => (
						  <Swiper.Item key={index} type={1}>
								<Image lazy src={`${item}`} width={`100%`} height={`100%`} fit='cover' 
									onClick={() => {
										ImageViewer.Multi.show({images: bannerImg,defaultIndex:index})
									}}
								/>
						  </Swiper.Item>
						))
					}
					</Swiper>
					<div className="right"
						onClick={() => {
							ref.current?.swipeNext()
						}}
					><img src={iconjt} /></div>
				</div>
				<div className="banner_bottom">
					<div className="banner-bottom-swiper">
						<div
							className={num === 0 && video === false?'bottom-swiper-slide cur':num === 1 && video === true?'bottom-swiper-slide cur':'bottom-swiper-slide'} 
							key={0} 
							style={images.length > 0 ? {display:'flex'}:{display:'none'}}
							onClick={() => {
								if( video === true){
									ref.current?.swipeTo(1)
								}else{
									ref.current?.swipeTo(0)
								}
							}}
						>
							<div className="border"></div>
							{<Image lazy src={images[0]} width={`100%`} height={`100%`} fit='cover' />}
						</div>
						<div
							className={num === 1 && video === false?'bottom-swiper-slide cur':num === 2 && video === true?'bottom-swiper-slide cur':'bottom-swiper-slide'} 
							key={1} 
							style={images.length > 1 ? {display:'flex'}:{display:'none'}}
							onClick={() => {
								if( video === true){
									ref.current?.swipeTo(2)
								}else{
									ref.current?.swipeTo(1)
								}
							}}
						>
							<div className="border"></div>
							<Image lazy src={images[1]} width={`100%`} height={`100%`} fit='cover' />
						</div>
						<div
							className={num === 2 && video === false?'bottom-swiper-slide cur':num === 3 && video === true?'bottom-swiper-slide cur':'bottom-swiper-slide'} 
							key={2} 
							style={images.length > 2 ? {display:'flex'}:{display:'none'}}
							onClick={() => {
								if( video === true){
									ref.current?.swipeTo(3)
								}else{
									ref.current?.swipeTo(2)
								}
							}}
						>
							<div className="border"></div>
							<Image lazy src={images[2]} width={`100%`} height={`100%`} fit='cover' />
						</div>
						<div
							className={num === 3 && video === false?'bottom-swiper-slide cur':num === 4 && video === true?'bottom-swiper-slide cur':'bottom-swiper-slide'} 
							key={3} 
							style={images.length > 3 ? {display:'flex'}:{display:'none'}}
							onClick={() => {
								if( video === true){
									ref.current?.swipeTo(4)
								}else{
									ref.current?.swipeTo(3)
								}
							}}
						>
							<div className="border"></div>
							<Image lazy src={images[3]} width={`100%`} height={`100%`} fit='cover' />
						</div>
						<div
							className={num > 3 && video === false?'bottom-swiper-slide cur':num > 4 && video === true?'bottom-swiper-slide cur':'bottom-swiper-slide'} 
							key={4}
							style={images.length > 4 ? {display:'flex'}:{display:'none'}}
							onClick={() => {
								if( video === true){
									ref.current?.swipeTo(5)
								}else{
									ref.current?.swipeTo(4)
								}
							}}
						>
							<div className="border"></div>
							<Image lazy src={num > 4?rightImg:`${images[4]}`} width={`100%`} height={`100%`} fit='cover' />
							<div className="num" style={images.length > 5 ? {display:'flex'}:{display:'none'}}><p>+{images.length}</p></div>
						</div>
					</div>
				</div>
				<div className="info">
					<div className="info_top">
						<p className="info_title">{list.buildingName}</p>
						<div className="info_addr">
							<img src={icon2} alt="icon" />
							<p>{list.address}</p>
						</div>
					</div>
					<div className="price_box">
						<div className="info_price">
						{
							list.price === '0' || list.price === '0.0' || list.price === '0.00'?
							<p className="price">N/A</p>
							:
							<p className="price">{list.price}{lng === 'zh-CN'?'万':lng === 'zh-TW'?'萬':null}</p>
						}
							
						</div>
						<div className="info_tab ">
							<div className={lng === 'en'?'en info_tab_li':'info_tab_li'}>
								<img src={icon5} alt="icon" />
								<p>{list.type}</p>
							</div>
							<div className={lng === 'en'?'en info_tab_li':'info_tab_li'}>
								<img src={icon6} alt="icon" />
								<p>{list.houseType}</p>
							</div>
						</div>
					</div>
					
					<div className="info_bottom">
						<p style={ list.city === null || list.city === '' ? hide : null}><span>{t('details.city')}：</span><span>{list.city}</span></p>
						<p style={ list.type === null || list.type === '' ? hide : null}><span>{t('details.type')}：</span><span>{list.type}</span></p>
				        <p style={list.area === null || list.area === '' ? hide : null}><span>{t('details.area')}：</span><span>{list.area}{t('details.areaUnit')}</span></p>
				        {
							list.unitPrice === '0' || list.unitPrice === '0.0' || list.unitPrice === '0.00'?
							<p style={list.unitPrice === null || list.unitPrice === '' ? hide : null}><span>{t('details.unitPrice')}：</span><span>N/A/{t('details.areaUnit')}</span></p>
							:
							<p style={list.unitPrice === null || list.unitPrice === '' ? hide : null}><span>{t('details.unitPrice')}：</span><span>${list.unitPrice}/{t('details.areaUnit')}</span></p>
						}
						
						<p style={ list.floor === null || list.floor === '' ? hide : null}><span>{t('details.floor')}：</span><span>{list.floor}</span></p>
						<p style={ list.towards === null || list.towards === '' ? hide : null}><span>{t('details.towards')}：</span><span>{list.towards}</span></p>
						<p style={ list.parkingSpace === null || list.parkingSpace === '' ? hide : null}><span>{t('details.parkingSpace')}：</span><span>{list.parkingSpace}</span></p>
						<p style={ list.storage === null || list.storage === '' ? hide : null}><span>{t('details.storage')}：</span><span>{list.storage}</span></p>
						<p style={ list.status === null || list.status === '' ? hide : null}><span>{t('details.status')}：</span><span>{list.status}</span></p>
						<p style={ list.builtYear === null || list.builtYear === '' ? hide : null}>{t('details.builtYear')}：<span>{list.builtYear}</span></p>
						<p style={ list.propertyType === null || list.propertyType === '' ? hide : null}>{t('details.propertyType')}：<span>{list.propertyType}</span></p>
						<p style={ list.assignmentFee === null || list.assignmentFee === '' ? hide : null}>{t('details.assignmentFee')}：<span>{list.assignmentFee}</span></p>
						<p style={ list.communityName === null || list.communityName === '' ? hide : null}><span>{t('details.communityName')}：</span><span>{list.communityName}</span></p>
						<p style={ list.builder === null || list.builder === '' ? hide : null}><span>{t('details.builder')}：</span><span>{list.builder}</span></p>
						<p style={ list.facility === null || list.facility === '' ? hide : null} className="cloum"><span>{t('details.facility')}：</span><span>{list.facility}</span></p>
					</div>
					<div className="house_text" style={ list.description === null || list.description === '' ? hide : null}>
						{list.description}
					</div>
					<div className="house_img">
						<p className="house_img_title">{t('details.RoomTypeIllustration')}：</p>
						<img src={houseType} alt="pic"
							onClick={() => {
								ImageViewer.show({image: houseType})
							}}
						 />
					</div>
				</div>
				<div className="list_title">
					<img className="list_icon" src={icon4} alt='icon' />
					<p>{t('details.LocationandSurroundings')}</p>
				</div>
				<Surroundings
					location={{
						lat: parseFloat(list?.latitude) ? parseFloat(list?.latitude) : 49.24913249999999,
						lng: parseFloat(list?.longitude) ? parseFloat(list?.longitude) : -123.1056808
					}}
					 nearbyDetailMap={list?.nearbyDetailMap ? list.nearbyDetailMap : {"PublicTransportation": [], "ShoppingMall": [], "Hospital": []}}  
				 />
				<div className="list_title">
					<img className="list_icon" src={icon4} alt='icon' />
					<p>{t('details.HotFAQ')}</p>
				</div>
				<Hotquestion />
				<Service type={0} houseID={props.match.params.id} />
			</div>
		</Box>
    );
}
export default OverlayVisible