// pcapi
export const rootUrl = "https://wapapi.PresaleVIP.com";   // 线上
export const ImageURL = "https://wapapi.PresaleVIP.com/static/download/"  // 线上
export const key = 'AIzaSyDnEsiIxj1mNJEylmYUQmV8BK3TL8ayg4g';   // 线上


// export const rootUrl = "https://king.wapapi.activatorlab.com";   // 测试
// export const ImageURL = "https://king.wapapi.activatorlab.com/static/download/"  // 测试
// export const key = 'AIzaSyBw7fvEiLxrvcvQQmGyoCsssBFIvHiKA7w' // 本地

export const TIMEOUT = 5000;