import React,{useEffect, useState} from 'react'
import styled from 'styled-components'
import Map from '../../components/Map'
import { useTranslation } from 'react-i18next';

import icon1_1 from "../../assets/img/icon-surroundings-01-1.png";
import icon1_2 from "../../assets/img/icon-surroundings-01-2.png";
import icon2_1 from "../../assets/img/icon-surroundings-02-1.png";
import icon2_2 from "../../assets/img/icon-surroundings-02-2.png";
import icon3_1 from "../../assets/img/icon-surroundings-03-1.png";
import icon3_2 from "../../assets/img/icon-surroundings-03-2.png";
const Box = styled.div`
	.map_box{
		width:100%;
		background:#fff;
		padding-top:0.27rem;
		padding-bottom:0.2rem;
		.adm-selector{
			overflow-x: scroll;
			.adm-space-horizontal.adm-space-wrap{
				flex-wrap: nowrap;
				.adm-space-item{
					.adm-selector-item{
						.label_box{
							display:flex;
							align-items:center;
							justify-content: center;
							img{
								height:0.27rem;
								margin-right:0.09rem;
							}
						}
					}
				}
			}
		}
		.map_tab{
			overflow-x: scroll;
			width: calc(100% - 0.4rem );
			margin-left:0.2rem;
			.map_tab_scroll{
				display:inline-flex;
				align-items:center;
				.label_box{
					height:0.5rem;
					background: #fff;
					border: 0.02rem solid #D9D9D9;
					border-radius: 0.05rem;
					font-size: 0.16rem;
					min-width: 1.1rem;
					display:flex;
					align-items:center;
					justify-content: center;
					padding:0 0.2rem;
					margin-right:0.07rem;
					cursor: pointer;
					img{
						margin-right:0.08rem;
						height:0.25rem;
					}
				}
				.label_box.cur{
					color: #fff;
					background: #D6AC58;
					border: 0.02rem solid #D6AC58;
				}
			}
		}
		.map_surroundings{
			width:100%;
			padding:0.2rem;
			display:flex;
			flex-direction: column;
			.li{
				width:100%;
				flex-direction: column;
				margin-bottom:0.1rem;
				.li_title{
					font-size:0.16rem;
					color:#000000;
				}
				.li_addr{
					width:100%;
					display:flex;
					justify-content: space-between;
					font-size:0.14rem;
					color:#9C9C9C;
				}
			}
		}
	}
`;

function Demo(props) {
	let {t} = useTranslation()
    const [cur, setCur] = useState('PublicTransportation')
    const [location, setLocation] = useState({});
    useEffect(() => {
        setLocation(props.location);
    }, [props]);
	return (
		<Box>
			<div className="map_box">
				<div className="map_tab">
					<div className="map_tab_scroll">
						<div className={cur=== 'PublicTransportation'?'label_box cur':'label_box'}
							onClick={
								()=>{
									setCur('PublicTransportation')
								}
							}
						>
							<img src={cur === 'PublicTransportation'?icon1_2:icon1_1} alt="icon" /><p>{t('Surroundings.Transportation')}</p>
						</div>
						<div className={cur=== 'ShoppingMall'?'label_box cur':'label_box'}
							onClick={
								()=>{
									setCur('ShoppingMall')
								}
							}
						>
							<img src={cur === 'ShoppingMall'?icon2_2:icon2_1} alt="icon" /><p>{t('Surroundings.Shopping')}</p>
						</div>
						<div className={cur=== 'Hospital'?'label_box cur':'label_box'}
							onClick={
								()=>{
									setCur('Hospital')
								}
							}
						>
							<img src={cur === 'Hospital'?icon3_2:icon3_1} alt="icon" /><p>{t('Surroundings.Medical')}</p>
						</div>
					</div>
				</div>
                <div className="map_surroundings">
                    {
                        props?.nearbyDetailMap[cur]?.map((item, index) => (
                            <div className="li" key={index}>
                                <p className="li_title">{item.name}</p>
                                <div className="li_addr">
                                    <p>{item.address}</p>
                                    <p>{item.distance}{t('Surroundings.km')}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
				<ErrorBoundary><Map location={location} currentNearby={props.nearbyDetailMap[cur]} type={cur} /></ErrorBoundary>
				
			</div>
		</Box>
	)
}
export default Demo

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div style={{
		fontSize:'16px',
		textAlign:'center',
		width:'100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}}><p></p></div>;
    }
    return this.props.children;
  }
}