import React, { useState } from 'react'
import { CheckList,Dropdown } from 'antd-mobile';
import { useTranslation } from 'react-i18next'
import I18n from "../../../I18next";
import styled from 'styled-components'
import LngSet from '../../../components/Lng_set'

import logoFt from "../../../assets/img/logo-ft.png";
import logoJt from "../../../assets/img/logo-cn.png";
import cn from "../../../assets/img/lng-cn.png";
import en from "../../../assets/img/lng-en.png";
import tw from "../../../assets/img/lng-tw.png";

const Box = styled.div`
	width:100%;
	height:0.7rem;
	display:flex;
	align-items:center;
	padding:0 0.16rem;
	justify-content: space-between;
	position: relative;
	.logo{
		height:0.54rem;
		margin-right:0.1rem;
	}
	>div{
		top:0;
		right:0.2rem;
		.lng{
			.lng-list{
				position: absolute;
				top: 0.6rem;
				right: 0;
			}
		}
	}
	.lng{
		height:0.7rem;
		display:flex;
		align-items:center;
		justify-content:center;
		margin-left:0.12rem;
		position: relative;
		img{
			height:0.34rem;
		}
		.adm-dropdown{
			position: absolute;
			width: 100%;
			height: 100%;
			background:none;
			opacity: 0;
			.adm-dropdown-nav{
				width: 100%;
				height: 100%;
				.adm-dropdown-item-title{
					&:after{
						display:none;
					}
				}
			}
		}
	}
	.adm-search{
		height:0.5rem;
		flex:1;
		.adm-search-input-box{
			height:100%;
		}
	} 
	@media (min-width: 750px){
		width:7.2rem;
		margin:0 auto;
		height:0.5rem;
		.lng{
			height:0.5rem;
		}
		.logo{
			height:0.4rem;
		}
	}
`;
function Hsearch(){
	const {t, i18n} = useTranslation()
	const lng = window.localStorage.getItem('i18nextLng')
	const [lnga, setlnga] = useState(lng)
	return (
		<Box>
			<img className="logo" src={lng === 'zh-CN'?logoJt:logoFt} alt="logo" />
			<LngSet />
			{/* <div className="lng">
				<img
					src={
						lng === 'zh-CN'?cn:lng === 'zh-TW'?tw:en
					} 
					alt="Language" 
				/>
				<Dropdown>
					<Dropdown.Item key='sorter' title='' forceRender destroyOnClose>
						<div style={{
							width:'100%',
							lineHeight:'0.5rem',
							paddingLeft:'12px',
							fontSize:'17px',
							borderBottom:'0.01rem solid #E8E5DD'
						}}>
							{t('Select_language')}
						</div>
						<CheckList defaultValue={lnga}>
							<CheckList.Item className={lng !== 'zh-CN' && lng !== 'zh-TW'?'cur':null} value='en' 
								onClick={
									()=>{i18n.changeLanguage('en');window.location.reload();}
								}
							>
								English
							</CheckList.Item>
							<CheckList.Item className={lng === 'zh-CN'?'cur':null} value='zh-CN' 
								onClick={
									()=>{i18n.changeLanguage('zh-CN');window.location.reload();}
								}
							>
								中文简体
							</CheckList.Item>
							<CheckList.Item className={lng === 'zh-TW'?'cur':null} value='zh-TW' 
								onClick={
									()=>{i18n.changeLanguage('zh-TW');window.location.reload();}
								}
							>
								中文繁體
							</CheckList.Item>
						</CheckList>
					</Dropdown.Item>
				</Dropdown>
			</div> */}
		</Box>
	)
}
export default Hsearch
