import React from 'react'
import styled from 'styled-components'
import YouTube from '../../components/YouTube'
import Tencent from '../../components/Tencent'


import icon from "../../assets/img/icon-back.png";
const Box = styled.div`
	width:100%;
	height:100%;
	background:#000;
	display:flex;
	align-items:center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	.back{
		position: absolute;
		height:0.36rem;
		left:0.2rem;
		top:0.2rem;
		z-index:10;
	}
	>div{
		width:100%;
	}
`;

function Video (props) {
    const lng = window.localStorage.getItem('i18nextLng')
	let title = document.getElementById('nav_title')
	let elng = window.localStorage.getItem('i18nextLng')
	if(elng === 'zh-CN'){
		title.innerText=`PresaleVIP.com 楼花王`
	}else{
		title.innerText=`PresaleVIP.com 樓花王`
	}
	if(lng !== 'zh-CN'){
		return (
			<Box>
				<img className="back" src={icon} alt="back" onClick={()=>{props.history.goBack()}} />
				<YouTube link={props.location.state.enLink}></YouTube>
			</Box>
		)
	}else{
		return (
			<Box>
				<img className="back" src={icon} alt="back" onClick={()=>{props.history.goBack()}} />
				<Tencent link={props.location.state.cnLink}></Tencent>
			</Box>
		)
	}
}
export default Video