import zh from './locales/zh.json'
import tw from './locales/tw.json'
import en from './locales/en.json'


// 语言资源文件
const resources = {
    "zh-TW": {
        translation: tw
    },
    "en": {
        translation: en
    },
    "zh-CN": {
        translation: zh
    }
}

export default resources;