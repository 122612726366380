import React, { useState,useEffect } from 'react'
import { CheckList,Dropdown,Selector } from 'antd-mobile';
import { withRouter } from "react-router";
import {  post , get} from '../../service';
import Houselist from '../../components/Houselist_vip'
import TabBar from '../../components/Tabbar'
import LngSet from '../../components/Lng_set'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

import bg from "../../assets/img/bg-01.png";
const Box = styled.div`
	width:100%;
	padding:0 0.2rem 1rem 0.2rem;
	.top_fixed{
		width:calc(100% - 0.4rem);
		max-width:7.2rem;
		background: #EDEDED;
		position:fixed;
		top:0;
		z-index:100;
		.top{
			margin-top:0.15rem;
			font-size:0.24rem;
			font-weight: 550;
			margin-bottom:0.14rem;
		}
		.adm-dropdown{
			background: none;
			.adm-dropdown-nav{
				justify-content: space-between;
				padding-bottom: 0.14rem;
				.adm-dropdown-item{
					width: 0.89rem;
					background: #fff;
					flex: inherit;
					border-radius: 0.05rem;
					box-shadow: 0 0.01rem 0.05rem rgba(197,185,168,0.3);
					padding-right:0.1rem;
					.adm-dropdown-item-title{
						font-size:0.16rem;
					}
				}
				.adm-dropdown-item.select{
					background: #D6AC58;
					color: #fff;
					.adm-dropdown-item-title{
						&:after{
							border-color: transparent transparent #fff #fff;
						}
					}
				}
				.adm-dropdown-item.adm-dropdown-item-active{
					background: #D6AC58;
					color: #fff;
					.adm-dropdown-item-title{
						&:after{
							border-color: transparent transparent #fff #fff;
						}
					}
				}
			}
		}
	}
	.list{
		margin-top:1.3rem;
	}
	@media (min-width: 750px){
		height:100vh;
		overflow-y:scroll;
		padding-bottom:1rem;
		&::-webkit-scrollbar{/*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
				width:14px;
				height:14px;
		}
		&::-webkit-scrollbar-button{/*滚动条两端的按钮，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置2）*/
				display:none;
		}
		&::-webkit-scrollbar-track{/*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-track-piece{/*内层轨道，滚动条中间部分（位置4）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-thumb{/*滚动条里面可以拖动的那部分（位置5）*/
			background:rgb(193,193,193);
		}
		&::-webkit-scrollbar-corner {/*边角（位置6）*/
			display:none;
		}
		&::-webkit-scrollbar-resizer  {/*定义右下角拖动块的样式（位置7）*/
			bdisplay:none;
		}
		.max-box{
			max-width:7.2rem;
			margin:0 auto 0 auto;
			.top_fixed{
				.top{
					width:7.2rem;
					height:0.52rem;
					background:#fff;
					margin:0;
					display:flex;
					align-items:center;
					padding:0.1rem 0 0.1rem 0.2rem;
					z-index:100;
					padding:0 0.2rem;
					>div{
						right:0.2rem;
						top:0.1rem;
					}
				}
				.adm-dropdown{
					position:fixed;
					top:0.52rem;
					width:7.2rem;
					z-index:10;
					background:#fff;
					padding:0 0.2rem;
					.adm-dropdown-nav{
						margin-bottom:0;
						.adm-dropdown-item{
							box-shadow:none;
						}
					}
				}
			}
			
			.list{
				margin-top:1.34rem;
				padding:0 0.2rem;
			}
		}
	}
`


function Vip(props) {
	let {t, i18n} = useTranslation()
    const priceRange = [
        { "minimumPrice": 0, "maximumPrice": 500000 },
        { "minimumPrice": 500000, "maximumPrice": 750000 },
        { "minimumPrice": 750000, "maximumPrice": 1000000 },
        { "minimumPrice": 1000000, "maximumPrice": 1500000 },
        { "minimumPrice": 1500000, "maximumPrice": 2000000 },
        { "minimumPrice": 2000000, "maximumPrice": 2147483647 }
    ]
    const priceList = [
        // { label: t('VIP.priceListItemAll'), value: 'ALL'},
        { label: t('VIP.priceListItem0'), value: 0},
        { label: t('VIP.priceListItem1'), value: 1 },
        { label: t('VIP.priceListItem2'), value: 2 },
        { label: t('VIP.priceListItem3'), value: 3 },
        { label: t('VIP.priceListItem4'), value: 4 },
        { label: t('VIP.priceListItem5'), value: 5 }
    ];
    const [sort, setSort] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [statusList, setStatusList] = useState([]);
	const [htypeList, setHtypeList] = useState([]);
    const [cities, setCities] = useState([]);
    const [status, setStatus] = useState([]);
    const [htype, setHtype] = useState([]);
    const [queryParam, setQueryParam] = useState();
	// 是否选中变量
	const [citySelect, setCitySelect] = useState(false);
	const [statusSelect, setStatusSelect] = useState(false);
	const [priceSelect, setPriceSelect] = useState(false);
	const [sortSelect, setSortSelect] = useState(false);

    // 从后台获取区域数据
    useEffect(() => {
		let title = document.getElementById('nav_title')
		let elng = window.localStorage.getItem('i18nextLng')
		if(elng === 'zh-CN'){
			title.innerText=`PresaleVIP.com 楼花王`
		}else{
			title.innerText=`PresaleVIP.com 樓花王`
		}
        post(`/wap/city/list`, {}).then((res) => {
            if (res && res?.data?.length > 0) {
                var locale = window.localStorage.getItem('i18nextLng');
                var label = '';
                var tempRegionList = []
                for (let i = 0; i < res.data.length; ++i) {
                    if (locale === 'en' || locale === 'EN') {
                        label = res.data[i].nameEn;
                    } else if (locale === 'zh-TW' || locale === 'ZHTW') {
                        label = res.data[i].nameZhtw;
                    } else {
                        label = res.data[i].nameZhcn;
                    }
                    tempRegionList.push({ label: label, value: res.data[i].id });
                }
                if (locale === 'en' || locale === 'EN' || locale === 'en-US') {
                    tempRegionList.push({ label: 'Other', value: 'other' });
                } else if (locale === 'ZHCN' || locale === 'zh-CN') {
                    tempRegionList.push({ label: '其他', value: 'other' });
                } else {
                    tempRegionList.push({ label: '其他', value: 'other' });
                }
                setRegionList(tempRegionList);
            }
        })
		// 类型
		post(`/wap/sys/allStatus`, {
		    key: "BUILDING_TYPE"
		}).then((res) => {
		    if (res && res?.data?.length > 0) {
		        var label = '';
		        var tempHouseTypes = [];
		        for (let i = 0; i < res.data.length; ++i) {
		            if (window.localStorage.getItem('i18nextLng') === 'en') {
		                label = res.data[i].nameEn;
		            } else if (window.localStorage.getItem('i18nextLng') === 'zh-TW') {
		                label = res.data[i].nameZhtw;
		            } else {
		                label = res.data[i].nameZhcn;
		            }
		            tempHouseTypes.push({ label: label, value: res.data[i].code });
		        }
		        setHtypeList(tempHouseTypes);
		    }
		});
		// 微信分享
		get(`/wechar/share/`,{
			url:window.location.href
		}).then( res => {
			window.wx.config({
			  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			  appId:  res.data.appId, // 必填，公众号的唯一标识
			  timestamp: res.data.timestamp, // 必填，生成签名的时间戳
			  nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
			  signature: res.data.signature,// 必填，签名，见附录1
			  jsApiList: [
				'updateAppMessageShareData',
				'updateTimelineShareData',
			  ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			})
		})
		// 判断是否微信浏览器
		const ua = window.navigator.userAgent.toLowerCase()
		if (ua.indexOf('micromessenger') < 0) return false
		// 开局调用分享
		window.wx.ready(() => {
			//分享给朋友
			window.wx.updateAppMessageShareData({
				title: '大温地区最新最全楼花项目信息 |  楼花王', // 分享标题
				desc: '第一选房 · 查看VIP价格 · 户型资料 · 特别优惠', // 分享描述
				link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: `https://www.presalevip.com${bg}`, // 分享图标
				success: function () {
				  // 用户确认分享后执行的回调函数
				},
				cancel: function () {
				  // 用户取消分享后执行的回调函数
				}
			})
			
			  //分享到朋友圈
			window.wx.updateTimelineShareData({
				title: '大温地区最新最全楼花项目信息 |  楼花王', // 分享标题
				link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: `https://www.presalevip.com${bg}`, // 分享图标
				success: function () {
				  // 用户确认分享后执行的回调函数
				},
				cancel: function () {
				  // 用户取消分享后执行的回调函数
				}
			})
		})
    }, []);

    // 从后台获取楼盘状态数据
    useEffect(() => {
        post(`/wap/sys/allStatus`, {
            key: "BUILDING_STATUS"
        }).then((res) => {
            if (res && res?.data?.length > 0) {
                var locale = window.localStorage.getItem('i18nextLng');
                var label = '';
                var tempStatusList = [];
                for (let i = 0; i < res.data.length; ++i) {
                    if (locale === 'en' || locale === 'EN') {
                        label = res.data[i].nameEn;
                    } else if (locale === 'zh-TW' || locale === 'ZHTW') {
                        label = res.data[i].nameZhtw;
                    } else {
                        label = res.data[i].nameZhcn;
                    }
                    tempStatusList.push({ label: label, value: res.data[i].code });
                }
                setStatusList(tempStatusList);
            }
        })
    }, []);
	
    function btnSubmit(e) {
        setQueryParam(
            {
                cities: cities,
                status: status,
                types: htype,
                orderBy: sort[0]
            }
        );
		if(e === 1){
			if(cities.length > 0){
				setCitySelect(true)
			}else{
				setCitySelect(false)
			}
		}else if(e === 2){
			if(htype.length > 0){
				setPriceSelect(true)
			}else{
				setPriceSelect(false)
			}
		}else if(e === 3){
			if(status.length > 0){
				setStatusSelect(true)
			}else{
				setStatusSelect(false)
			}
		}else if(e === 4){
			if(sort.length > 0){
				setSortSelect(true)
			}else{
				setSortSelect(false)
			}
		}
	}
	return(
		<Box>
			<div className="max-box">
				<div className="top_fixed">
					<div className="top">
					    <p>{t('VIP.top')}</p>
						<LngSet />
					</div>
					<Dropdown>
						{/* 区域 */}
					    <Dropdown.Item
					        key='region'
					        title={t('VIP.region')}
					        closeOnContentClick
					        forceRender
					        destroyOnClose
							className={citySelect === true?'select':null}
					    >
							<div onClick={(e)=>e.stopPropagation()}>
					            <div className="title">{t('VIP.regionSelect')}</div>
								<Selector
									options={regionList}
									multiple={true}
					                onChange={(arr, context) => {
					                    var selectedItems = [];
					                    for (let i = 0; i < context.items.length; ++i) {
					                        if (context.items[i].value === 'ALL') {
					                            selectedItems = null;
					                            break;
					                        } else {
					                            selectedItems.push(context.items[i].value);
					                        }
					                    }
					                    setCities(selectedItems);
					                }}
								/>
							</div>
					        <div className="btn_ok" onClick={()=>btnSubmit(1)}>{t('VIP.confirmButtonText')}</div>
						</Dropdown.Item>
						
						{/* 类型 */}
						<Dropdown.Item
						    key='price'
						    title={t('VIP.type')}
						    closeOnContentClick
						    forceRender
						    destroyOnClose
							className={priceSelect === true?'select':null}
						>
							<div onClick={(e)=>e.stopPropagation()}>
						        <div className="title">{t('VIP.typeSelect')}</div>
								<Selector
									options={htypeList}
									multiple={true}
						            onChange={(arr, context) => {
						                var selectedItems = [];
										for (let i = 0; i < context.items.length; ++i) {
										    if (context.items[i].value === 'ALL') {
										        selectedItems = null;
										        break;
										    } else {
										        selectedItems.push(context.items[i].value);
										    }
										}
						                setHtype(selectedItems);
						            }}
								/>
							</div>
						    <div className="btn_ok" onClick={() => btnSubmit(2)}>{t('VIP.confirmButtonText')}</div>
						</Dropdown.Item>
						
						{/* 状态 */}
					    <Dropdown.Item
					        key='status'
					        title={t('VIP.status')}
					        closeOnContentClick
					        forceRender
					        destroyOnClose
							className={statusSelect === true?'select':null}
					    >
							<div onClick={(e)=>e.stopPropagation()}>
								<div className="title">{t('VIP.statusSelect')}</div>
								<Selector
									options={statusList}
									multiple={true}
									// onClick={(e)=>e.stopPropagation()}
					                onChange={(arr, context) => {
					                    var selectedItems = [];
					                    for (let i = 0; i < context.items.length; ++i) {
					                        if (context.items[i].value === 'ALL') {
					                            selectedItems = null;
					                            break;
					                        } else {
					                            selectedItems.push(context.items[i].value);
					                        }
					                    }
					                    setStatus(selectedItems);
					                }}
								/>
							</div>
					        <div className="btn_ok" onClick={() => btnSubmit(3)}>{t('VIP.confirmButtonText')}</div>
						</Dropdown.Item>
						
						{/* 排序 */}
						<Dropdown.Item key='sort' 
						title={t('VIP.sort')} 
							closeOnContentClick 
							forceRender
							destroyOnClose
							className={sortSelect === true?'select':null}
						>
					        <CheckList
					            onChange={(arr) => {
					                setSort(arr);
					            }}
							>
					            <CheckList.Item onClick={(e)=>e.stopPropagation()} className={sort[0] === 'DEFAULT'?'cur':null} value="DEFAULT">{t('VIP.defaultSort')}</CheckList.Item>
					            <CheckList.Item onClick={(e) => e.stopPropagation()} className={sort[0] === 'BUILT_YEAR_ASC' ? 'cur' : null} value="BUILT_YEAR_ASC">{t('VIP.builtYearASC')}</CheckList.Item>
								<CheckList.Item onClick={(e)=>e.stopPropagation()} className={sort[0] === 'PRICE_ASC'?'cur':null} value="PRICE_ASC">{t('VIP.minimumPriceASC')}</CheckList.Item>
								<CheckList.Item onClick={(e)=>e.stopPropagation()} className={sort[0] === 'PRICE_DESC'?'cur':null} value="PRICE_DESC">{t('VIP.minimumPriceDESC')}</CheckList.Item>
							</CheckList>
							<div className="btn_ok" onClick={() => btnSubmit(4)}>{t('VIP.confirmButtonText')}</div>
						</Dropdown.Item>
					</Dropdown>
				</div>
				<div className="list"><Houselist queryParam={queryParam} /></div>
			</div>
			<TabBar />
		</Box>
	)
}
export default withRouter(Vip)