import React,{useState,useEffect} from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'
import TabBar from '../../components/Tabbar'
import Search from './HomeSearch'
import BannerSwiper from './HomeSwiper'
import House_list from './HomeHouseListVip'
import Homeblock from './HomeBlock'
import Houselistcolumn from './HomeHouseListTransfer'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

import list_zs from "../../assets/img/icon-01.png";
import flogo from "../../assets/img/foot-logo.png";
const Box = styled.div`
	font-size:0.24rem;
	padding-bottom:1rem;
	.search{
		width: 100%;
		height:0.7rem;
		background: #fff;
		position: fixed;
		top: 0;
		left: 0%;
		z-index:10;
		box-shadow: 0 0.03rem 0.06rem rgba(102,102,102,0.2);
	}
	.banner{
		width:100%;
		position: relative;
		margin-top:0.7rem;
		.b_logo{
			position: absolute;
			left:0.16rem;
			top:0.25rem;
			width:1.56rem;
			z-index:10;
		}
	}
	.list{
		width:100%;
		padding:0 0.2rem;
		display:flex;
		flex-direction: column;
		margin-top:0.19rem;
		.list_title{
			display:flex;
			align-items:center;
			font-size:0.2rem;
			color:#000;
			margin-bottom: 0.11rem;
			font-weight: 550;
			img{
				width:0.12rem;
				margin-right:0.053rem;
			}
		}
		.ok_btn{
			width:100%;
			line-height:0.5rem;
			font-size:0.16rem;
			color:#666666;
			text-align:center;
			margin-top:0.15rem;
			border-radius:0.05rem;
			background:#D6AC58;
			color:#fff;
		}
	}
	.list.column{
		padding:0;
		.list_title{
			padding:0 0.2rem;
		}
		.ok_btn{
			width:calc(100% - 0.4rem);
			margin:0 auto;
			margin-top:0.1rem;
		}
	}
	.foot{
		width:100%;
		padding:0 0.2rem;
		display:flex;
		flex-direction: column;
		align-items:center;
		font-size:0.12rem;
		color:#9C9C9C;
		background: #EDEDED;
		text-align:center;
		line-height:0.24rem;
		img{
			height:0.6rem;
		}
	}
	@media (min-width: 750px){
		height:100vh;
		overflow-y:scroll;
		padding-bottom:1rem;
		&::-webkit-scrollbar{/*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
				width:14px;
				height:14px;
		}
		&::-webkit-scrollbar-button{/*滚动条两端的按钮，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置2）*/
				display:none;
		}
		&::-webkit-scrollbar-track{/*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-track-piece{/*内层轨道，滚动条中间部分（位置4）*/
				background:rgb(241,241,241);
		}
		&::-webkit-scrollbar-thumb{/*滚动条里面可以拖动的那部分（位置5）*/
			background:rgb(193,193,193);
		}
		&::-webkit-scrollbar-corner {/*边角（位置6）*/
			display:none;
		}
		&::-webkit-scrollbar-resizer  {/*定义右下角拖动块的样式（位置7）*/
			bdisplay:none;
		}
		.search{
			height:0.5rem;
			margin: 0;
			z-index: 100;
			display:flex;
			align-items:center;
		}
		.max-box{
			max-width:7.2rem;
			margin:0.5rem auto 0 auto;
			background:#fff;
			.banner{
				margin-top:0.5rem;
			}
			.list.column{
				padding-bottom:0.2rem
			}
		}
	}
`

function Home (props) {
	let {t, i18n} = useTranslation()
	useEffect(() => {
		let title = document.getElementById('nav_title')
		let elng = window.localStorage.getItem('i18nextLng')
		if(elng === 'zh-CN'){
			title.innerText=`PresaleVIP.com 楼花王`
		}else{
			title.innerText=`PresaleVIP.com 樓花王`
		}
	}, []);
    return (
		<Box>
			<div className="search">
				<Search />
			</div>
			<div className="max-box">
				<div className="banner">
					<BannerSwiper />
				</div>
				
				<Homeblock />
				
				<div className="list">
					<div className="list_title">
						<img className="list_icon" src={list_zs} alt='icon' />
						<p>{t('home.HotPresaleVIP')}</p>
					</div>
					<House_list />
					<NavLink to="/vip" className="ok_btn">{t('home.more')}</NavLink>
				</div>
				<div className="list column">
					<div className="list_title">
						<img className="list_icon" src={list_zs} alt='icon' />
						<p>{t('home.HotPresaleTransfer')}</p>
					</div>
					<Houselistcolumn />
					<NavLink to="/king_transfer" className="ok_btn">{t('home.more')}</NavLink>
				</div>
				<div className="foot">
					<img src={flogo} alt="logo" />
					<p>Eric Ho Personal Real Estate Corporation， Sutton Group 1st West Realty Inc. 140 – 4664 Lougheed Hwy Burnaby BC Canada V5C 5T5 604-299-9680</p>
				</div>
			</div>
			<TabBar />
			
		</Box>
    );
}
export default withRouter(Home)