import React, { useState } from 'react'
import { CheckList,Dropdown,Mask } from 'antd-mobile';
import { useTranslation } from 'react-i18next'
import I18n from "../../I18next";
import styled from 'styled-components'

import icon from "../../assets/img/icon-lng.png";
import cn from "../../assets/img/lng-cn.png";
import en from "../../assets/img/lng-en.png";
import tw from "../../assets/img/lng-tw.png";
import cn2 from "../../assets/img/lng-cn-2.png";
import en2 from "../../assets/img/lng-en-2.png";
import tw2 from "../../assets/img/lng-tw-2.png";
const Box = styled.div`
	display:flex;
	align-items:center;
	justify-content: space-between;
	position: absolute;
	right: 0;
	top: 0.15rem;
	z-index:100;
	.lng{
		display:flex;
		align-items:center;
		justify-content:center;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		z-index:10000;
		img{
			height:0.36rem;
			cursor: pointer;
		}
		.lng-list{
			height:0;
			overflow:hidden;
			transition: all .4s;
			display: flex;
			flex-direction: column;
			align-items: center;
			img{
				margin-top:0.1rem;
				cursor: pointer;
			}
		}
		.lng-list.show{
			height:2rem;
		}
	}
`;
function Hsearch(){
	const {t, i18n} = useTranslation()
	const lng = window.localStorage.getItem('i18nextLng')
	const [lnga, setlnga] = useState(false)
	const [visible, setVisible] = useState(false)
	function show () {
		if(lnga === false){
			setlnga(true)
			setVisible(true)
		}else{
			setlnga(false)
			setVisible(false)
		}
	}
	return (
		<Box>
			<div className="lng">
				<img
					src={icon}
					alt="Language" 
					onClick={
						()=>{
							show()
						}
					}
				/>
				<div className={lnga === true?'lng-list show':'lng-list'}>
					<img src={lng !== 'zh-CN' && lng !== 'zh-TW'?en:en2} alt="Language" 
						onClick={
							()=>{i18n.changeLanguage('en');window.location.reload();}
						}
					 />
					<img src={lng === 'zh-CN'?cn:cn2} alt="Language" 
						onClick={
							()=>{i18n.changeLanguage('zh-CN');window.location.reload();}
						}
					 />
					<img src={lng === 'zh-TW'?tw:tw2} alt="Language" 
						onClick={
							()=>{i18n.changeLanguage('zh-TW');window.location.reload();}
						}
					 />
				</div>
			</div>
			<Mask visible={visible} onMaskClick={() => {setVisible(false);setlnga(false)}}>
			</Mask>
		</Box>
	)
}
export default Hsearch
