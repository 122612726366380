import React from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import styled from 'styled-components'

import vip from "../../../assets/img/icon-nav-01.png";
import map from "../../../assets/img/icon-nav-02.png";
import transfer from "../../../assets/img/icon-nav-04.png";
import us from "../../../assets/img/icon-nav-03.png";
const Box = styled.div`
	.nav_ul{
		width:100%;
		padding:0 0.2rem;
		display:flex;
		justify-content: space-between;
		margin-top:0.19rem;
		.nav_li{
			width:0.8rem;
			height:1.1rem;
			display:flex;
			align-items:center;
			justify-content:center;
			flex-direction: column;
			background:#fff;
			font-size:0.12rem;
			font-weight: 550;
			color:#404040;
			img{
				height:0.52rem;
				margin-bottom:0.12rem;
			}
			p{
				text-align:center;
				    transform: scale(0.9);
			}
		}
	}
	
`
function Block (props) {
	const { t } = useTranslation();
    return (
		<Box>
			<div className="nav_ul">
				<NavLink to="/vip" className="nav_li">
					<img src={vip} alt="nav_img" />
					<p>{t('PresaleKingVIP')}</p>
				</NavLink>
				<NavLink to="/map_house" className="nav_li">
					<img src={map} alt="nav_img" />
					<p>{t('MapHouse')}</p>
				</NavLink>
				<NavLink to="/king_transfer" className="nav_li">
					<img src={transfer} alt="nav_img" />
					<p>{t('KingTransfer')}</p>
				</NavLink>
				<NavLink to="/contact_us" className="nav_li">
					<img src={us} alt="nav_img" />
					<p>{t('ContactUs')}</p>
				</NavLink>
			</div>
		</Box>
    );
}
export default withRouter(Block)