import React from 'react'
import { TabBar } from 'antd-mobile'
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import styled from 'styled-components'


import home_1 from "../../assets/img/tabbar-01-1.png";
import home_2 from "../../assets/img/tabbar-01-2.png";
import vip_1 from "../../assets/img/tabbar-02-1.png";
import vip_2 from "../../assets/img/tabbar-02-2.png";
import map_1 from "../../assets/img/tabbar-03-1.png";
import map_2 from "../../assets/img/tabbar-03-2.png";
import transfer_1 from "../../assets/img/tabbar-04-1.png";
import transfer_2 from "../../assets/img/tabbar-04-2.png";
import us_1 from "../../assets/img/tabbar-05-1.png";
import us_2 from "../../assets/img/tabbar-05-2.png";
const Foot = styled.div`
	width:100%;
	height:0.8rem;
	background:#fff;
	position:fixed;
	bottom:0;
	left:0;
	box-shadow: 0 -0.03rem 0.06rem rgba(102,102,102,0.2);
	.tabbar{
		min-height:100%;
	}
	.adm-tab-bar-item{
		color:#000;
		justify-content: space-around;
	}
	.adm-tab-bar-item-active{
		color:#D6AC58;
	}
	@media (min-width: 750px){
		height:0.6rem;
		.adm-tab-bar-item{
			padding:0 8px;
		}
	}
`


function Tabbar(props){
	const { t } = useTranslation();
	const imgStyle= {
		width:"0.28rem"
	}
	const cur = props.location.pathname
	return (
		<Foot>
			 <TabBar className="tabbar"
			 onChange={
				(e)=>{
					props.history.push(`${e}`)
				}
			 }
			 defaultActiveKey={cur}
			 >
					<TabBar.Item
						title={t('index')}
						key="/"
						icon= {(active: boolean) =>active ? <img alt="img" style={imgStyle} src={home_2} /> : <img alt="img" style={imgStyle} src={home_1} />}
					>
					</TabBar.Item>
					<TabBar.Item
						title={t('PresaleKingVIP')}
						key="/vip"
						icon= {(active: boolean) =>active ? <img alt="img" style={imgStyle} src={vip_2} /> : <img alt="img" style={imgStyle} src={vip_1} />}
					>
					</TabBar.Item>
					<TabBar.Item
						title={t('MapHouse')}
						key="/map_house"
						icon= {(active: boolean) =>active ? <img alt="img" style={imgStyle} src={map_2} /> : <img alt="img" style={imgStyle} src={map_1} />}
					>
					</TabBar.Item>
					<TabBar.Item
						title={t('KingTransfer')}
						key="/king_transfer"
						icon= {(active: boolean) =>active ? <img alt="img" style={imgStyle} src={transfer_2} /> : <img alt="img" style={imgStyle} src={transfer_1} />}
					>
					</TabBar.Item>
					<TabBar.Item
						title={t('ContactUs')}
						key="/contact_us"
						icon= {(active: boolean) =>active ? <img alt="img" style={imgStyle} src={us_2} /> : <img alt="img" style={imgStyle} src={us_1} />}
					>
					</TabBar.Item>
				</TabBar>
		</Foot>
	)
}

export default withRouter(Tabbar)
