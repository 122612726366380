import React,{useState} from 'react'
import YouTube from 'react-youtube';

/**
 * 我们暂定认为传过来的腾讯视频的链接形式为：https://www.youtube.com/watch?v={我们需要的视频id}
 */
function queryURLParameter(url){
	let reg = /([^?&=]+)=([^?&=]+)/g
	let obj = {}
	url.replace(reg,function(){
		obj[arguments[1]] = arguments[2]
	})
	return obj
}
function VideoIframe (props){
	const [link, setLink] = useState(queryURLParameter(props.link))
	const opts = {
	  width: '100%',
	  playerVars: { 
	    autoplay: 1
	  }
	};
	return(
		<YouTube
		  videoId={link.v}
		  opts={opts}
		  // onReady={this._onReady}
		/>
	)
}
export default VideoIframe